const stateEnum = {
  Disabled: 0,
  NotDisabled: 1,
};

const isDisabled = (disabledState) => disabledState === stateEnum.Disabled;
const isNotDisabled = (disabledState) =>
  disabledState === stateEnum.NotDisabled;

const DisabledState = {
  ...stateEnum,
  isDisabled,
  isNotDisabled,
};

export default DisabledState;
