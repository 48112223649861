import axios from "./axios-uploadFile";
import { AuthStore, downloadAttachmentLib } from "../../../libs";
import { config as _config } from "../../../_config";
import { addAttachments as _addAttachments } from "./uploadFileAttachmentService";
import { v4 as uuid } from "uuid";
const { getStream: _getStream, downloadAttachment: _downloadAttachment } =
  downloadAttachmentLib;

const queryString = require("query-string");

const getHistoryList = async (offset, fseId, dateFrom, dateTo) => {
  let token = await AuthStore.getAuthenticationToken();

  try {
    let queryStringParameters = {
      offset: offset,
      fseId: fseId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };

    var response = await axios({
      url: `${_config.fogFilesEndPoint}/?${queryString.stringify(
        queryStringParameters
      )}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

function copyProperties(copy, originals) {
  for (const key in originals) {
    if (originals.hasOwnProperty(key)) {
      copy[key] = originals[key];
    }
  }
}

const addFile = async (fseDetails, fields) => {
  let data = {
    FSE_c: fseDetails["custom:fse_id"],
  };

  copyProperties(data, fields);
  data["RecordName"] = `${fseDetails.fseReference}-file-${uuid()}`;

  let files = data.files;
  delete data.files;

  try {
    var response = await AddFileDetails(data);
    return await addAttachments(response, files);
  } catch (error) {
    return Promise.reject(error);
  }
};

async function addAttachments(response, files) {
  let data = response.data;
  try {
    await _addAttachments(response.data.id, files);
    return Promise.resolve(response);
  } catch (error) {
    await deleteFile(data.Id);
    return Promise.reject(error);
  }
}

async function AddFileDetails(data) {
  let token = await AuthStore.getAuthenticationToken();

  try {
    var response = await axios({
      url: `${_config.fogFilesEndPoint}/`,
      method: "post",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function deleteFile(id) {
  let token = await AuthStore.getAuthenticationToken();
  var response = await axios({
    url: `${_config.fogFilesEndPoint}/${id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return response;
}

const getFile = async (id) => {
  let token = await AuthStore.getAuthenticationToken();
  try {
    var response = await axios({
      url: `${_config.fogFilesEndPoint}/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
async function getStream(id, data) {
  const params = {
    id,
    data,
    fileType: "Files_c",
    endpoint: _config.fogFilesEndPoint,
    axios,
  };
  return await _getStream(params);
}

const downloadAttachment = async (id, data) => {
  const params = {
    id,
    data,
    fileType: "Files_c",
    endpoint: _config.fogFilesEndPoint,
    axios,
  };
  return await _downloadAttachment(params);
};

export {
  getHistoryList,
  addFile,
  deleteFile,
  getFile,
  getStream,
  downloadAttachment,
};
