const AttachmentsService = (axios, endpoint, authStore, attachmentHelper) => {
  let _axiosHeaders = null;

  const create = async (id, files) => {
    try {
      const promises = files.map((item) =>
        handleAddingFileAttachment(id, item)
      );
      await Promise.all(promises);
    } catch (error) {
      throw error;
    }
  };

  const setAxiosHeaders = async () => {
    _axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: await authStore.getAuthenticationToken(),
    };
  };

  const handleAddingFileAttachment = async (id, data) => {
    const stream = data.stream?.split("base64,")[1];

    const element = {
      FileName: data.metadata.name,
      FileContents: `${stream}`,
      UploadedFileContentType: "application/pdf",
      Title: data.metadata.name,
      DatatypeCode: "File",
      CategoryName: "MISC",
    };

    await addFileAttachmentDetails(id, element);
  };

  const addFileAttachmentDetails = async (id, element) => {
    try {
      let config = getPostAxiosConfig(element, `/${id}/child/Attachment`);
      var response = await axios(config);
      return Promise.resolve(response);
    } catch (error) {
      if (!error.response) {
        const message = 'An error occurred while uploading the file';
    
        throw new Error(message);
      } 

      return Promise.reject(error);
    }
  };

  const getPostAxiosConfig = (data, path) =>
    getBaseAxiosConfig(data, "post", path);

  const getBaseAxiosConfig = (data, method, path = "") => {
    return {
      url: `${endpoint}${path}`,
      method: method,
      data: data,
      headers: { ..._axiosHeaders },
    };
  };

  return { create, setAxiosHeaders };
};

export default AttachmentsService;
