let PDFJS;
if (process.env.NODE_ENV === "test") {
  PDFJS = require("pdfjs-dist");
} else {
  PDFJS = require("pdfjs-dist/webpack");
}

async function genPdfPreview(stream) {
  let pdf = await PDFJS.getDocument(stream).promise;
  if (pdf.numPages >= 1) {
    let pages = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      let page = await pdf.getPage(i);
      const scale = "1.5";
      const viewport = page.getViewport({
        scale: scale,
        dontFlip: false,
        rotation: 0,
      });
      const canvas = document.createElement("canvas");
      const canvasContext = canvas.getContext("2d");
      canvas.height = viewport.height || viewport.viewBox[3];
      canvas.width = viewport.width || viewport.viewBox[2];
      await page.render({
        canvasContext,
        viewport,
      }).promise;
      pages.push(canvas.toDataURL());
    }

    return pages;
  }
  return null;
}

export { genPdfPreview };
