import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";

import {
  useAppContext,
  onError,
  useFormFields,
  LoadingState,
  AuthenticationState as AuthenticatedState,
  SignInState,
} from "../../libs";
import { LoaderButton, Errors, FormFields } from "../../components";
import { WhiteTextLogo as AppLogo } from "../../assets";
import { login as constraints } from "../../validation";
import {
  isValidAuthSignIn,
  getDisabledStatus,
  isValidFormValidation,
} from "./helpers";
import "./Login.scss";

const { PasswordField, TextInput } = FormFields;

export default function Login() {
  const history = useHistory();
  const [loadedFlag, setLoadedFlag] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(LoadingState.Idle);
  const { setUserAuthenticationStatus } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    password: "",
  });
  const [validationResults, setValidationResults] = useState({});
  const [validated, setValidated] = useState(false);

  const tryLogin = async () => {
    try {
      let results = isValidFormValidation(fields, constraints);
      setValidated(results.valid);
      setValidationResults(results.results);

      if (results.valid) {
        let signInState = await isValidAuthSignIn(fields);
        handleSignInState(results.valid, signInState);
      } else {
        setLoadingStatus(LoadingState.Idle);
      }
    } catch (error) {
      let message = onError(error);
      setValidationResults({ signIn: [message] });
      setLoadingStatus(LoadingState.Rejected);
    }
  };

  const handleSignInState = (isValid, signInState) => {
    if (
      !isValid ||
      (!SignInState.isValidSignIn(signInState) &&
        !SignInState.isChangePassword(signInState))
    ) {
      setLoadingStatus(LoadingState.Idle);
    } else if (isValid && SignInState.isChangePassword(signInState)) {
      history.push("/ChangePassword");
      setLoadingStatus(LoadingState.Resolved);
    } else if (isValid && SignInState.isValidSignIn(signInState)) {
      setUserAuthenticationStatus(AuthenticatedState.Authenticated);
      setLoadingStatus(LoadingState.Resolved);
      pushSignInState();
    }
  };

  const pushSignInState = async () => {
    history.push("/");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoadingStatus(LoadingState.Pending);
    await tryLogin();
  };

  useEffect(() => {
    setUserAuthenticationStatus(AuthenticatedState.NotAuthenticated);
    setLoadedFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedFlag]);

  return (
    loadedFlag && (
      <div className="Login">
        <Container fluid>
          <Row>
            <Col xs={12} lg={4} className={"p-0"}>
              <div className="login-image img img--bg contain">
                <div className="logo">
                  <img src={AppLogo} alt=""></img>
                </div>
                <Form
                  className="form form--login"
                  onSubmit={onSubmit}
                  noValidate
                  validated={validated}
                >
                  <Errors messages={validationResults.signIn}></Errors>
                  <TextInput
                    id="username"
                    name="Username"
                    label="Username"
                    placeholder=""
                    value={fields.username}
                    handleChange={handleFieldChange}
                    isInvalid={validationResults.username}
                  ></TextInput>
                  <PasswordField
                    id="password"
                    name="password"
                    label="Password"
                    value={fields.password}
                    handleChange={handleFieldChange}
                    isInvalid={validationResults.password}
                  ></PasswordField>
                  <LoaderButton
                    block
                    size="lg"
                    disabledStatus={getDisabledStatus(fields)}
                    loadingStatus={loadingStatus}
                    type="submit"
                    className="login"
                    variant={"success"}
                  >
                    Sign In
                  </LoaderButton>
                  <LoaderButton
                    block
                    size="lg"
                    loadingStatus={loadingStatus}
                    type="button"
                    className="login"
                    variant={"success"}
                    href="/CreatePrimaryAccount"
                  >
                    Create Account
                  </LoaderButton>
                  {/* <div className="forgot-password">
                    <a>Forgotten password?</a>
                  </div> */}
                </Form>
              </div>
            </Col>
            <Col lg={8} className="d-none d-lg-block p-0">
              <div className="fog-image img img--bg"></div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
}
