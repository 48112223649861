import { addFilePreviewProperties } from "./addFilePreviewProperties";
import AuthenticationState from "./authenticationStateLib";
import AuthStore, { FSE_ID, USER_TYPE_ID } from "./authStoreLib";
import CognitoState from "./CognitoStateLib";
import CognitoUserCustom from "./CognitoUserCustomLib";
import { AppContext, useAppContext } from "./contextLib";
import DisabledState from "./disabledStateLib";
import eclipseApi from "./eclipseApiLib";
import { onError } from "./errorLib";
import { genPdfPreview } from "./generatePdfPreview";
import { handleNavState, onMobileDevice } from "./handleNavStateLib";
import { useFormFields } from "./hooksLib";
import LoadingState from "./loadingStateLib";
import SideBarState from "./sideBarStateLib";
import SignInState from "./signInStateLib";
import store from "./storeLib";
import { Admin, EcasUser, User } from "./userGroupConstants";
import Svgs from "./svgLib";
import {
  downloadAttachment,
  findFileContents,
  findParent,
  getAttachmentId,
  getLinkPath,
  getStream,
} from "./downloadAttachmentLib";
import {
  getAccount,
  getApprovedFses,
  axiosAccount,
  axiosAccountExternal,
  approvedFsesQueryBuilder,
} from "./services";

const downloadAttachmentLib = {
  downloadAttachment,
  findFileContents,
  findParent,
  getAttachmentId,
  getLinkPath,
  getStream,
};
const services = {
  getAccount,
  getApprovedFses,
  axiosAccount,
  axiosAccountExternal,
  approvedFsesQueryBuilder,
};

export {
  addFilePreviewProperties,
  AuthenticationState,
  AuthStore,
  FSE_ID,
  USER_TYPE_ID,
  CognitoState,
  CognitoUserCustom,
  AppContext,
  useAppContext,
  DisabledState,
  eclipseApi,
  onError,
  genPdfPreview,
  handleNavState,
  onMobileDevice,
  useFormFields,
  LoadingState,
  SideBarState,
  SignInState,
  store,
  Svgs,
  downloadAttachmentLib,
  services,
  Admin,
  EcasUser,
  User,
};
