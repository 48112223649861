/* global COGNITO_REGION, COGNITO_USER_POOL_ID, COGNITO_IDENTITY_POOL_ID, COGNITO_APP_CLIENT_ID */
// The above commented line is a directive for JSLint and is required to build the app successfully without resolving those variables
// The config values are available globally, from config.js which is an external file added after the build and included as a script in public/index.html

import Amplify from "@aws-amplify/auth";

global.COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION
global.COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID
global.COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
global.COGNITO_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_APP_CLIENT_ID

const amplifyConfig = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: COGNITO_REGION,
      userPoolId: COGNITO_USER_POOL_ID,
      identityPoolId: COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: COGNITO_APP_CLIENT_ID,
    },
  });
};

export { amplifyConfig };
