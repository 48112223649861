import axios from "axios";
import { setAxiosEclipseAuthorizationHeaderValue } from "../../_config/axiosConfig";
import { config as _config } from "../../_config";

const instance = axios.create({
  baseURL: _config.accountsEndpoint,
});

setAxiosEclipseAuthorizationHeaderValue(instance);

export default instance;
