import Errors from "./Errors";
import PageHeaderWithLogo from "./PageHeaderWithLogo";

import AppliedRoute from "./AppliedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import LoaderButton from "./LoaderButton";
import {
  CheckboxField,
  SelectField,
  DateField,
  EmailField,
  PasswordField,
  TextInput,
  UploadFileField,
  PreviewFileModal,
} from "./FormFields";
import {
  education,
  envelope,
  history,
  menu,
  password,
  phone,
  twitter,
  user,
} from "./svgs";

const FormFields = {
  CheckboxField,
  SelectField,
  DateField,
  EmailField,
  PasswordField,
  TextInput,
  UploadFileField,
  PreviewFileModal,
};
const Svgs = {
  education,
  envelope,
  history,
  menu,
  password,
  phone,
  twitter,
  user,
};

export {
  Errors,
  FormFields,
  PageHeaderWithLogo,
  Svgs,
  AppliedRoute,
  AuthenticatedRoute,
  LoaderButton,
};
