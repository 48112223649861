import { DisabledState } from "../../libs";
import { validate } from "../../validation";

const isValidFormValidation = (fields, constraints) => {
  return validate(fields, constraints);
};

const getDisabledStatus = (fields) => {
  let isValidForm =
    fields.newPassword.length > 0 && fields.newPasswordCheck.length > 0;
  return isValidForm ? DisabledState.NotDisabled : DisabledState.Disabled;
};

export { isValidFormValidation, getDisabledStatus };
