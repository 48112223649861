import React from "react";

export default function MenuIcon({ className = "" }) {
  return (
    <svg
      width="21px"
      height="14px"
      viewBox="0 0 21 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          className="menu"
          transform="translate(0.000000, -122.000000)"
          fill="#000000"
        >
          <g id="Group" transform="translate(0.000000, 122.034528)">
            <path
              d="M19.3214944,7.79607303 L0.813337079,7.79607303 C0.364157303,7.79607303 0,7.43195506 0,6.98273596 C0,6.53351685 0.364157303,6.16939888 0.813337079,6.16939888 L19.3214944,6.16939888 C19.7707135,6.16939888 20.1348315,6.53355618 20.1348315,6.98273596 C20.1348315,7.43195506 19.7707135,7.79607303 19.3214944,7.79607303"
              id="Fill-1"
            ></path>
            <path
              d="M19.3214944,1.62667416 L0.813337079,1.62667416 C0.364157303,1.62667416 0,1.26251685 0,0.813337079 C0,0.364157303 0.364157303,-1.53210777e-14 0.813337079,-1.53210777e-14 L19.3214944,-1.53210777e-14 C19.7707135,-1.53210777e-14 20.1348315,0.364157303 20.1348315,0.813337079 C20.1348315,1.26251685 19.7707135,1.62667416 19.3214944,1.62667416"
              id="Fill-3"
            ></path>
            <path
              d="M19.3214944,13.9654719 L0.813337079,13.9654719 C0.364157303,13.9654719 0,13.6013539 0,13.1521348 C0,12.7029157 0.364157303,12.3387978 0.813337079,12.3387978 L19.3214944,12.3387978 C19.7707135,12.3387978 20.1348315,12.7029157 20.1348315,13.1521348 C20.1348315,13.6013539 19.7707135,13.9654719 19.3214944,13.9654719"
              id="Fill-4"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
