import { userTypeOptions } from "../pages/Account/helpers";

let userTypes = userTypeOptions.map((item, id) => {
  return item.value;
});

export const constraints = {
  email: {
    presence: true,
    email: true,
  },
  username: {
    presence: true,
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
    length: {
      minimum: 6,
      tooShort: "needs to have %{count} characters or more",
    },
  },
   fseId: {
     presence: true,
   },
  userTypeId: {
    presence: true,
    inclusion: {
      within: userTypes,
      message: "^%{value} is not within the list",
    },
  },
};
