import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { Auth } from "@aws-amplify/auth";

const USER_GROUPS = "userGroups";
const COGNITO_GROUPS = "cognito:groups";
class CognitoUserCustom extends CognitoUser {
  constructor(cognitoUser, externalAttributes) {
    const currentConfig = Auth.configure();
    super({
      Username: cognitoUser.username,
      Pool: new CognitoUserPool({
        UserPoolId: currentConfig.userPoolId,
        ClientId: currentConfig.userPoolWebClientId,
      }),
    });
    
    this.copy(cognitoUser, this);

    Object.assign(this.attributes, externalAttributes);

    this.attributes[USER_GROUPS] = cognitoUser.signInUserSession?.idToken?.payload[COGNITO_GROUPS];
  }

  getUserDataProperty() {
    var attributes = [];
    for (const key in this.attributes) {
      if (this.attributes.hasOwnProperty(key)) {
        attributes.push({ Name: key, Value: this.attributes[key] });
      }
    }
    return { UserAttributes: attributes, Username: this.username };
  }

  belongToGroups(...groups) {
    let response = false;
    for (var group of groups) {
      response = response || this.attributes[USER_GROUPS]?.includes(group);
    }
    return response;
  }

  copy(source, deep) {
    let o, prop, type;
    if (typeof source != "object" || source === null) {
      o = source;
      return o;
    }
    for (prop in source) {
      if (source.hasOwnProperty(prop)) {
        type = typeof source[prop];
        if (deep && type === "object" && source[prop] !== null) {
          if (deep[prop] === undefined) deep[prop] = {};
          deep[prop] = this.copy(source[prop], deep[prop]);
        } else {
          if (deep === null) {
            deep = {};
          }
          deep[prop] = source[prop];
        }
      }
    }
    return deep;
  }
}

export default CognitoUserCustom;
