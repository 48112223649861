import axios from "./axios-account";
import AuthStore from "../authStoreLib";
import { config as _config } from "../../_config";
const queryString = require("query-string");

const getApprovedFses = async (inputValue, offset) => {
  let queryStringParameters = {
    inputValue: inputValue,
    offset: offset,
  };
  return await sendRequest({
    url: `${_config.fogAccountsEndpoint}/fses?${queryString.stringify(
      queryStringParameters
    )}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getAccount = async (partyNumber) => {
  return await sendRequest({
    url: `${_config.fogAccountsEndpoint}/${partyNumber}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const sendRequest = async (params) => {
  let token = await AuthStore.getAuthenticationToken();
  if (params.headers) {
    params.headers.Authorization = token;
  } else {
    params.headers = {
      Authorization: token,
    };
  }
  try {
    var response = await axios(params);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getApprovedFses, getAccount };
