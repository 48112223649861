import { categoryOptions, isFogCategory } from "../pages/UploadFile/helpers";
let categoryTypes = categoryOptions.map((item, i) => {
  return item.value;
});

export const constraints = {
  FileType_c: {
    presence: true,
    inclusion: {
      within: categoryTypes,
      message: "^%{value} is not within the list",
    },
  },
  Date_c: {
    presence: true,
    datetime: {
      // dateOnly: true,
    },
  },
  WasteCompany_c: function (
    value,
    attributes,
    attributeName,
    options,
    constraints
  ) {
    if (isFogCategory(attributes)) return null;
    return {
      presence: true,
      length: {
        minimum: 1,
        message: "^Waste Company must be at least 1 character",
      },
    };
  },
  WasteDocumentNo_c: function (
    value,
    attributes,
    attributeName,
    options,
    constraints
  ) {
    if (isFogCategory(attributes)) return null;
    return {
      presence: true,
      length: {
        minimum: 1,
        message: "^Waste Document Number must be at least 1 character",
      },
    };
  },
  WasteQuantity_c: function (
    value,
    attributes,
    attributeName,
    options,
    constraints
  ) {
    if (isFogCategory(attributes)) return null;
    return {
      presence: true,
      numericality: {
        greaterThan: 0,
        notGreaterThan: "^Waste Quantity must be greater than 0",
      },
      length: {
        minimum: 1,
      },
    };
  },
};
