import React from "react";

export default function EducationIcon({ className = "" }) {
  return (
    <svg
      width="15px"
      height="20px"
      viewBox="0 0 15 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>book</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Main-Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Education"
          className="education"
          transform="translate(-26.000000, -163.000000)"
          fill="#000000"
          fillRule="nonzero"
        >
          <g id="book" transform="translate(26.000000, 163.000000)">
            <path
              d="M0.625,17.8125 C0.279804687,17.8125 0,17.5326563 0,17.1875 L0,2.578125 C0,1.15652344 1.15652344,0 2.578125,0 L13.75,0 C14.0951953,0 14.375,0.27984375 14.375,0.625 L14.375,15 C14.375,15.3451563 14.0951953,15.625 13.75,15.625 C13.4048047,15.625 13.125,15.3451563 13.125,15 L13.125,1.25 L2.578125,1.25 C1.84578125,1.25 1.25,1.84578125 1.25,2.578125 L1.25,17.1875 C1.25,17.5326563 0.970195313,17.8125 0.625,17.8125 Z"
              id="Path"
            ></path>
            <path
              d="M13.75,20 L2.8125,20 C1.26167969,20 0,18.7383203 0,17.1875 C0,15.6366797 1.26167969,14.375 2.8125,14.375 L13.75,14.375 C14.0951953,14.375 14.375,14.6548437 14.375,15 C14.375,15.3451563 14.0951953,15.625 13.75,15.625 L2.8125,15.625 C1.9509375,15.625 1.25,16.3259375 1.25,17.1875 C1.25,18.0490625 1.9509375,18.75 2.8125,18.75 L13.75,18.75 C14.0951953,18.75 14.375,19.0298437 14.375,19.375 C14.375,19.7201563 14.0951953,20 13.75,20 Z"
              id="Path"
            ></path>
            <path
              d="M13.75,17.8125 L2.8125,17.8125 C2.46730469,17.8125 2.1875,17.5326563 2.1875,17.1875 C2.1875,16.8423437 2.46730469,16.5625 2.8125,16.5625 L13.75,16.5625 C14.0951953,16.5625 14.375,16.8423437 14.375,17.1875 C14.375,17.5326563 14.0951953,17.8125 13.75,17.8125 Z"
              id="Path"
            ></path>
            <path
              d="M3.4375,15.625 C3.09230469,15.625 2.8125,15.3451563 2.8125,15 L2.8125,0.625 C2.8125,0.27984375 3.09230469,0 3.4375,0 C3.78269531,0 4.0625,0.27984375 4.0625,0.625 L4.0625,15 C4.0625,15.3451563 3.78269531,15.625 3.4375,15.625 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
