import React from "react";
import { categoryOptions } from "../helpers";
import Moment from "react-moment";
import _ from "lodash";
import { config } from "../../../_config";

const renderDotElement = (className) => {
  return <div className={`file-dot ${className}`}></div>;
};

const renderCategoryElement = (idx) => {
  var found = _.find(categoryOptions, function (o) {
    return o.value === idx;
  });

  if (found === undefined) return idx;
  return (
    <>
      {renderDotElement(found.className)}
      {found.label}
    </>
  );
};

const renderTableRow = (index, i, onClickRow) => {
  return (
    <tr key={index} onClick={(e) => onClickRow(e, i.Id)}>
      <td>{i.RecordName}</td>
      <td>
        <Moment date={i.Date_c} format={config.dateFormat.toUpperCase()} />
      </td>
      <td>{renderCategoryElement(i.FileType_c)}</td>
      <td>{i.WasteCompany_c}</td>
      <td>{i.WasteDocumentNo_c}</td>
      <td>{i.WasteQuantity_c}</td>
      <td>{i.Attachment?.length}</td>
    </tr>
  );
};

export { renderCategoryElement, renderDotElement, renderTableRow };
