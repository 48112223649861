import React, { useState, useEffect, useReducer } from "react";
import { Container, Form, Navbar, Row, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderButton, FormFields } from "../../../components/";

import { AddFileModal, ViewFileModal } from "../";

import { config } from "../../../_config";
import {
  SideBarState,
  AuthStore,
  useAppContext,
  useFormFields,
} from "../../../libs";
import { renderTableRow } from "./helpers";
import { getHistoryList } from "../services";
import "./History.scss";

const { DateField } = FormFields;

export default function History() {
  const [addFileModalShow, setAddFileModalShow] = useState(false);
  const [detailsFileModalShow, setDetailsFileModalShow] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const { navState } = useAppContext();
  const [filterFields, handleFilterFieldChange] = useFormFields({
    dateFrom: "",
    dateTo: "",
  });
  const [eclipseData, setEclipseData] = useState([]);
  const isOnMobile = SideBarState.isOnMobile(navState);

  function rowClick(rowId, action) {
    if (action === "reset") return;
    return action;
  }

  const [fileId, setFileId] = useReducer(rowClick);

  function onClick() {
    setAddFileModalShow(true);
  }

  const loadData = async (reset = false) => {
    const offset = reset ? 0 : eclipseData.length;
    var user = await AuthStore.currentAuthenticatedUser();
    var historyList = await getHistoryList(
      offset,
      user.attributes["custom:fse_id"],
      filterFields.dateFrom,
      filterFields.dateTo
    );

    let items = [];
    if (reset) {
      items = [...historyList.data.items];
    } else {
      items = [...eclipseData, ...historyList.data.items];
    }
    setEclipseData(items);
    setHasMore(historyList.data.hasMore);
  };

  const fetchMoreData = async () => {
    await loadData();
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [filterFields]);

  const refreshFunction = async () => {
    await loadData(true);
  };
  function onClickRow(e, fileId) {
    setDetailsFileModalShow(true);
    setFileId(fileId);
  }

  function onDateChange(e) {
    handleFilterFieldChange(e);
    setEclipseData([]);
  }
  function renderMobileNav(addFileButton, searchFormRow) {
    return (
      <>
        <Navbar
          id="navbar"
          bg="light"
          className="justify-content-between"
          variant="light"
        >
          <Navbar.Brand href="#">History</Navbar.Brand>
          {addFileButton}
        </Navbar>
        <Navbar className="navbar__secondary">
          <Form>{searchFormRow}</Form>
        </Navbar>
      </>
    );
  }

  function renderDesktopNav(addFileButton, searchFormRow) {
    return (
      <Navbar
        id="navbar"
        bg="light"
        className="justify-content-between"
        variant="light"
      >
        <Navbar.Brand href="#">History</Navbar.Brand>
        <Form inline>
          {searchFormRow}
          {addFileButton}
        </Form>
      </Navbar>
    );
  }

  function renderNavBar() {
    let addFileButton = renderAddFileButton();
    let groupClass = isOnMobile ? "col-6" : "";

    let searchFormRow = (
      <Row>
        <DateField
          id="dateFrom"
          label="From:"
          inLine={false}
          selected={filterFields.dateFrom}
          dateFormat={config.dateFormat}
          startDate={filterFields.dateFrom}
          endDate={filterFields.dateTo}
          handleChange={onDateChange}
          groupClass={groupClass}
          ignoreDST={true}
        />
        <DateField
          id="dateTo"
          label="To:"
          inLine={false}
          selected={filterFields.dateTo}
          dateFormat={config.dateFormat}
          startDate={filterFields.dateFrom}
          endDate={filterFields.dateTo}
          minDate={filterFields.dateFrom}
          handleChange={onDateChange}
          groupClass={groupClass}
          ignoreDST={true}
        />
      </Row>
    );

    return isOnMobile
      ? renderMobileNav(addFileButton, searchFormRow)
      : renderDesktopNav(addFileButton, searchFormRow);
  }

  function renderAddFileButton() {
    return (
      <LoaderButton
        type="button"
        variant="success"
        className="add-file"
        onClick={onClick}
      >
        Add file
      </LoaderButton>
    );
  }

  function renderTable() {
    return (
      <Table hover>
        <thead>
          <tr>
            <th> File Name </th>
            <th> Date Added </th>
            <th> Category </th>
            <th> Waste Company </th>
            <th> Waste Document No. </th>
            <th> Waste Quantity </th>
            <th> No. of Attachments </th>
          </tr>
        </thead>
        <tbody id="table_body">
          {eclipseData.map((i, index) => renderTableRow(index, i, onClickRow))}
        </tbody>
      </Table>
    );
  }

  return (
    <div className="History">
      <Container fluid className="p-0">
        {renderNavBar()}
        <div className="infinite-table">
          <div id="table_body" className="body-table">
            {renderTable()}
            <InfiniteScroll
              dataLength={eclipseData.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<h4 className="initial-message">Select start date and end date to display file history.</h4>}
              scrollableTarget="table_body"
              refreshFunction={refreshFunction}
            ></InfiniteScroll>
          </div>
        </div>
      </Container>
      <AddFileModal
        show={addFileModalShow}
        onHide={() => setAddFileModalShow(false)}
        onSubmit={() => refreshFunction()}
      ></AddFileModal>
      <ViewFileModal
        show={detailsFileModalShow}
        fileId={fileId}
        onHide={() => {
          setFileId("reset");
          setDetailsFileModalShow(false);
        }}
      ></ViewFileModal>
    </div>
  );
}
