import React from "react";
import { InputGroup, Form } from "react-bootstrap";
import {
  fogColor,
  ucoColor,
  mixedColor,
  fieldBackgroundColor,
  fieldBorderColor,
} from "../../css/Variables.module.scss";
import { DisabledState } from "../../libs";
import { validate } from "../../validation";
import { FormFields } from "../../components";

const { TextInput, DateField, UploadFileField, SelectField } = FormFields;

const categoryOptions = [
  {
    value: "FOG",
    label: "Fat, Oils and Grease (FOG)",
    color: fogColor,
    className: "fog",
  },
  {
    value: "UCO",
    label: "Used cooking oil (UCO)",
    color: ucoColor,
    className: "uco",
  },
  {
    value: "Mixed",
    label: "FOG & UCO (Mixed)",
    color: mixedColor,
    className: "mixed",
  },
];

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});
const fseStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: fieldBackgroundColor,
    borderColor: fieldBorderColor,
  }),
  option: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const getDisabledStatus = (fields) => {
  let fileTypeValid = fields.FileType_c.length > 0;
  let isFog = fileTypeValid && isFogCategory(fields);
  let dateValid = fields.Date_c !== "";
  let fogValid = isFog && dateValid;
  let nonFogValid =
    !isFog &&
    fileTypeValid &&
    dateValid &&
    fields.WasteCompany_c.length > 0 &&
    fields.WasteDocumentNo_c.length > 0 &&
    fields.WasteQuantity_c.length > 0;

  let isValid = fogValid || nonFogValid;
  return isValid ? DisabledState.NotDisabled : DisabledState.Disabled;
};

const isValidFormValidation = (fields, constraints) => {
  return validate(fields, constraints);
};

const isFogCategory = (fields) => {
  return fields.FileType_c === "FOG";
};

function genTextInput(
  id,
  label,
  fields,
  handleFieldChange,
  type = "text",
  readOnly = false,
  validationResults
) {
  let wasteAppend = (
    <InputGroup.Append>
      <InputGroup.Text>Litres</InputGroup.Text>
    </InputGroup.Append>
  );

  if (validationResults === undefined && readOnly) {
    validationResults = {};
    validationResults[id] = false;
  }

  let appendHtml = id === "WasteQuantity_c" ? wasteAppend : "";
  return (
    <TextInput
      icon=""
      id={id}
      name={id}
      label={label}
      type={type}
      placeholder=""
      value={fields[id]}
      handleChange={handleFieldChange}
      inputGroupAppend={appendHtml}
      isInvalid={validationResults[id]}
      readOnly={readOnly}
    ></TextInput>
  );
}

function genDateField(
  fields,
  config,
  handleFieldChange,
  readOnly,
  validationResults
) {
  let props = {
    id: "Date_c",
    label: "Date",
    selected: fields.Date_c,
    dateFormat: config.dateFormat,
    handleChange: handleFieldChange,
    readOnly: readOnly,
    ignoreDST: true,
  };
  if (!readOnly) {
    props["isInvalid"] = validationResults.Date_c;
  }

  return <DateField {...props} />;
}

function genSelectField(
  fields,
  handleFieldChange,
  validationResults,
  readOnly
) {
  let props = {
    id: "FileType_c",
    name: "FileType_c",
    label: "Category",
    placeholder: "Enter Category",
    handleChange: handleFieldChange,
    options: categoryOptions,
    isClearable: true,
    styles: fseStyles,
    isDisabled: readOnly,
    value: fields.FileType_c,
  };

  if (!readOnly) {
    props["isInvalid"] = validationResults.FileType_c;
  }
  return <SelectField {...props}></SelectField>;
}

function genAttachmentField(
  fields,
  handleFieldChange,
  label,
  readOnly,
  onClick
) {
  return (
    <Form.Group className="m-0">
      <Form.Label>{label}</Form.Label>
      <UploadFileField
        id="files"
        accept="image/*,.pdf,.csv"
        className="btn-add"
        label="+"
        value={fields.files}
        handleChange={handleFieldChange}
        onClick={onClick}
        groupClassName={"m-0"}
        readOnly={readOnly}
      ></UploadFileField>
    </Form.Group>
  );
}

function genFileModalBody(
  fields,
  handleFieldChange,
  config,
  readOnly = true,
  validationResults
) {
  var nonFogFields = (
    <>
      {genTextInput(
        "WasteCompany_c",
        "Waste Company",
        fields,
        handleFieldChange,
        "text",
        readOnly,
        validationResults
      )}
      {genTextInput(
        "WasteDocumentNo_c",
        "Waste Document No.",
        fields,
        handleFieldChange,
        "text",
        readOnly,
        validationResults
      )}
      {genTextInput(
        "WasteQuantity_c",
        "Waste Quantity",
        fields,
        handleFieldChange,
        "number",
        readOnly,
        validationResults
      )}
    </>
  );

  var body = isFogCategory(fields) ? <></> : nonFogFields;
  return (
    <>
      {genDateField(
        fields,
        config,
        handleFieldChange,
        readOnly,
        validationResults
      )}
      {body}
    </>
  );
}
export {
  categoryOptions,
  fseStyles,
  getDisabledStatus,
  isValidFormValidation,
  genTextInput,
  genFileModalBody,
  genSelectField,
  genAttachmentField,
  isFogCategory,
};
