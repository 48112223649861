import { useHistory, withRouter } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import {
  AuthStore,
  AppContext,
  onError,
  AuthenticationState,
  onMobileDevice,
  handleNavState,
} from "../libs";
import Routes from "../Routes";

import Layout from "./Layout";
import "../css/App.scss";
import { UserProvider } from "../libs/userContext";

export default withRouter(function App(props) {
  const history = useHistory();
  const [authenticatedStatus, setUserAuthenticationStatus] = useState(false);
  const [isAuthenticating, userIsAuthenticating] = useState(true);
  const [sideBarOpen, setSideBarOpen] = useState(
    !onMobileDevice(window.innerWidth)
  );
  const [navState, setNavState] = useState(handleNavState(sideBarOpen));

  const onLoad = useCallback(async () => {
    try {
      const currentSession = await AuthStore.currentSession();
      if (currentSession) {
        setUserAuthenticationStatus(AuthenticationState.Authenticated);
      } else {
        history.push("/login");
      }
    } catch (error) {
      if (error !== "No current user") {
        onError(error);
      }
    }

    userIsAuthenticating(false);
  }, [setUserAuthenticationStatus, history]);

  useEffect(() => {
    // moved onLoad inside useEffect to fix a missing dependency
    // https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
    onLoad();
  }, [onLoad]);

  let contextValue = {
    authenticatedStatus,
    setUserAuthenticationStatus,
    navState,
    setNavState,
    sideBarOpen,
    setSideBarOpen,
  };

  return (
    !isAuthenticating && (
      <div className="App">
        <AppContext.Provider value={contextValue}>
          <UserProvider>
            <Layout>
              <Routes />
            </Layout>
          </UserProvider>
        </AppContext.Provider>
      </div>
    )
  );
});
