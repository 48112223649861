import { screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

const testIdPrefix = "input-component";

function getSelectValueDOMElementById(id) {
  let control = screen.getByTestId(`${testIdPrefix}-${id}`);
  let value = control.querySelector('[type="hidden"]');
  return value;
}

function getSelectDOMElementById(id) {
  let control = screen.getByTestId(`${testIdPrefix}-${id}`);
  control = control.querySelector(`#${id}`);
  control = control.firstChild;
  return control;
}

function getInputDOMElementById(id) {
  let control = screen.getByTestId(`${testIdPrefix}-${id}`);
  control = control.querySelector(`#${id}`);
  return control;
}

async function setDateFieldWithTodayById(id) {
  let control = screen.getByTestId(`${testIdPrefix}-${id}`);
  let element = getInputDOMElementById(id);
  await userEvent.click(element);
  var buttonDayToday = control.querySelector(".react-datepicker__day--today");
  await userEvent.click(buttonDayToday);
}

export {
  testIdPrefix,
  getSelectValueDOMElementById,
  getSelectDOMElementById,
  getInputDOMElementById,
  setDateFieldWithTodayById,
};
