import React from "react";

export default function UserIcon({ className = "" }) {
  return (
    <svg
      width="21px"
      height="28px"
      viewBox="0 0 21 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>Password 4</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Main-Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Login"
          className="password"
          transform="translate(-504.000000, -556.000000)"
          fill="#000000"
        >
          <g id="Group-4" transform="translate(504.000000, 556.017175)">
            <g id="Group-3">
              <path
                d="M19.2998948,23.4133186 C19.2998948,25.0412876 17.977833,26.369267 16.3439464,26.369267 L4.5205134,26.369267 C2.89225567,26.369267 1.56456495,25.0472052 1.56456495,23.4133186 L1.56456495,13.7606794 L19.2998948,13.7606794 L19.2998948,23.4133186 Z M18.9418845,12.2017722 L18.9418845,8.57043196 C18.9418845,3.87689588 15.1258742,0.0549680412 10.4264206,0.0549680412 C5.73288454,0.0549680412 1.9109567,3.87097835 1.9109567,8.57043196 C1.9109567,9.00342165 2.25742062,9.34988557 2.69041031,9.34988557 C3.1234,9.34988557 3.46957526,9.00342165 3.46957526,8.57043196 C3.46957526,4.73695773 6.58724536,1.61387526 10.4264206,1.61387526 C14.2598948,1.61387526 17.3830495,4.73132887 17.3830495,8.57043196 L17.3830495,12.2017722 L1.50676082,12.2017722 C0.675348454,12.2017722 2.88659794e-05,12.8772361 2.88659794e-05,13.7085763 L2.88659794e-05,23.4133186 C2.88659794e-05,25.901566 2.02627629,27.9278856 4.51459588,27.9278856 L16.3439464,27.9278856 C18.832266,27.9278856 20.8585134,25.901566 20.8585134,23.4133186 L20.8585134,13.7085763 C20.8585134,12.8772361 20.1830495,12.2017722 19.3517814,12.2017722 L18.9418845,12.2017722 Z"
                id="Fill-1"
              ></path>
            </g>
            <path
              d="M10.4321,18.8178763 C11.1827598,18.8178763 11.788801,19.4298351 11.788801,20.1746495 C11.788801,20.9193918 11.1827598,21.5370515 10.4321,21.5370515 C9.6816567,21.5370515 9.07561546,20.9250206 9.07561546,20.1802784 C9.07561546,19.4355361 9.6816567,18.8178763 10.4321,18.8178763 M10.4321,23.0958866 C12.0428216,23.0958866 13.3477082,21.7853711 13.3477082,20.1802784 C13.3477082,18.5695567 12.0371928,17.2648866 10.4321,17.2648866 C8.82130619,17.2648866 7.51670825,18.5754742 7.51670825,20.1802784 C7.51670825,21.7853711 8.82130619,23.0958866 10.4321,23.0958866"
              id="Fill-4"
            ></path>
            <polygon
              id="Fill-6"
              points="1.91309278 12.2017938 3.46673196 12.2017938 3.46673196 8.45679381 1.91309278 8.45679381"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
}
