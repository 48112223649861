const stateEnum = {
  ForceChangePassword: "FORCE_CHANGE_PASSWORD",
  NewPasswordRequired: "NEW_PASSWORD_REQUIRED",
};

const CognitoState = {
  ...stateEnum,
};

export default CognitoState;
