const stateEnum = {
  Idle: 0,
  Pending: 1,
  Resolved: 2,
  Rejected: 3,
};

const isIdle = (loadingState) => loadingState === LoadingState.Idle;
const isPending = (loadingState) => loadingState === LoadingState.Pending;
const isResolved = (loadingState) => loadingState === LoadingState.Resolved;
const isRejected = (loadingState) => loadingState === LoadingState.Rejected;

const LoadingState = {
  ...stateEnum,
  isIdle,
  isPending,
  isResolved,
  isRejected,
};

export default LoadingState;
