const stateEnum = {
  InvalidSignIn: 0,
  ValidSignIn: 1,
  ChangePassword: 2,
};

const isInvalidSignIn = (signInState) =>
  signInState === SignInState.InvalidSignIn;
const isValidSignIn = (signInState) => signInState === SignInState.ValidSignIn;
const isChangePassword = (signInState) =>
  signInState === SignInState.ChangePassword;

const SignInState = {
  ...stateEnum,
  isInvalidSignIn,
  isValidSignIn,
  isChangePassword,
};

export default SignInState;
