import React, { useEffect, useState, useReducer } from "react";
import { Container, Navbar, ListGroup } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import { AuthStore, FSE_ID } from "../../libs";
import { ClientLinksGenerator, EclipseLinksGenerator } from "./data";
import "./Education.scss";

export default function Education() {
  function setSectionsState(state, action) {
    let property = action.property;
    let value = action.value;
    if (Object.hasOwnProperty.call(state, property)) {
      state[property] = value;
    }
    let properties = Object.values(state);
    const hasMore = properties.find(
      (property) => property !== undefined && property.hasMore === true
    );
    state.hasMore = hasMore !== undefined;
    return state;
  }

  const initialState = {
    eclipseLinks: { hasMore: true, length: 0 },
    clientLinks: { hasMore: true, length: 0 },
    hasMore: true,
  };
  const [sectionsState, dispatchSectionState] = useReducer(
    setSectionsState,
    initialState
  );

  const [eclipseData, setEclipseData] = useState([]);
  const [reload, setReload] = useState(true);

  const loadData = async () => {
    var user = await AuthStore.currentAuthenticatedUser();

    let sections = [...eclipseData];
    const sectionGenerators = getSectionGenerators(user, sections);
    for (const generator of sectionGenerators) {
      let result = await generator.generate();
      dispatchSectionState(result);
    }

    sections.sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    );
    setEclipseData(sections);
    setReload(false);
  };

  function getSectionGenerators(user, sections) {
    return [
      new ClientLinksGenerator({ user, sections }),
      new EclipseLinksGenerator({
        sections,
        offset: sectionsState.eclipseLinks.length,
        partyId: user.attributes[FSE_ID],
      }),
    ];
  }
  const refreshFunction = async () => {
    setEclipseData([]);
    await loadData();
  };

  const fetchMoreData = async () => {
    await loadData();
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionsState, reload]);

  function genRowHeader(data, index) {
    if (data.header !== undefined) {
      return (
        <ListGroup.Item key={index} className="list-header">
          <label>{data.header.Title}</label>
        </ListGroup.Item>
      );
    }
    return null;
  }
  function genRowItem(data, index) {
    let url = data.Url;
    let title = data.Title;
    let description =
      data.Description === "" ? "No description" : data.Description;
    let element = (
      <a href={url} target="_blank" rel="noreferrer">
        {title}
        {data.InstallationType_c}
      </a>
    );

    return (
      <ListGroup.Item key={index}>
        <div className="href-container">{element}</div>
        <div>
          <span>{description}</span>
        </div>
      </ListGroup.Item>
    );
  }
  function genRow(data, index) {
    let section = [genRowHeader(data, index)];
    for (let i = 0; i < data.items.length; i++) {
      const item = data.items[i];
      let row = genRowItem(item, `${data.header.Title + i}`);
      section.push(row);
    }

    return section;
  }

  return (
    <div className="Education">
      <Container fluid className="p-0">
        <Navbar id="navbar" bg="light" variant="light">
          <Navbar.Brand href="#">Education</Navbar.Brand>
        </Navbar>
        <Container fluid>
          <div className="list-container">
            <ListGroup variant="flush" id="listGroup">
              {eclipseData.map((i, index) => genRow(i, index))}
            </ListGroup>
            <InfiniteScroll
              dataLength={eclipseData.length}
              next={fetchMoreData}
              hasMore={sectionsState.hasMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget="listGroup"
              refreshFunction={refreshFunction}
            ></InfiniteScroll>
          </div>
        </Container>
      </Container>
    </div>
  );
}
