import React from "react";
import { useState, useEffect } from "react";

import "./SideBar.scss";
import { getMenuItems } from "./data";
import { mapToMenuLink, mapToSupportLink } from "./helpers";
import { useUser } from "../../libs/userContext";

export default function SideBar(props) {
  const {user} = useUser()
  const [menuItems, setMenuItems] = useState({ links: [], support: [] });

  useEffect(() => {  
    if (user != null) {
      setMenuItems(() => getMenuItems(user));
    }
  }, [user])

  return (
    <>
      <div className="sidebar__links">
        {mapToMenuLink(menuItems.links, props.openSideBar)}
      </div>
      <div className="sidebar__support">
        {mapToSupportLink(menuItems.support)}
      </div>
    </>
  );
}
