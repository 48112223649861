import axios from "./axios-uploadFile";
import { AuthStore, downloadAttachmentLib } from "../../../libs";
import { config as _config } from "../../../_config";
import AttachmentService from "../../../services/attachmentService";

const { getAttachmentId, findFileContents } = downloadAttachmentLib;

const attachmentService = AttachmentService(
  axios,
  _config.fogFilesEndPoint,
  AuthStore,
  {
    getAttachmentId,
    findFileContents,
  }
);

const addAttachments = async (id, files) => {
  await attachmentService.setAxiosHeaders();
  return await attachmentService.create(id, files);
};

export { addAttachments };
