import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  function resetForm() {
    setValues({ ...initialState });
  }

  return [
    fields,
    function (event) {
      let target = event.target;
      setValues({
        ...fields,
        [target.id]: target.type === "checkbox" ? target.checked : target.value,
      });
    },
    resetForm,
  ];
}
