import axios from "./axios-email";
import { AuthStore } from "../libs";

const sendEmail = async (path) => {
  let token = await AuthStore.getAuthenticationToken();
  try {
    var response = await axios({
      url: path,
      method: "post",
      data: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { sendEmail };
