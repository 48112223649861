import axios from "./axios-education";
import { AuthStore } from "../../../libs";
import { config as _config } from "../../../_config";

const queryString = require("query-string");

const getDocuments = async (offset, partyId) => {
  let queryStringParameters = {
    offset: offset,
    partyId: partyId,
  };

  return await get({
    url: `?${queryString.stringify(queryStringParameters)}`,
  });
};

const getCount = async (installationType) => {
  let queryStringParameters = {
    installationType: installationType,
  };

  return await get({
    url: `count?${queryString.stringify(queryStringParameters)}`,
  });
};

const get = async (params) => {
  let request = {
    ...params,
    method: "get",
  };
  return await sendRequest(request);
};

const sendRequest = async (params) => {
  let token = await AuthStore.getAuthenticationToken();
  let request = {
    ...params,
    url: `${_config.fogEducationEndPoint}/${params.url}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  try {
    var response = await axios(request);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getDocuments, getCount };
