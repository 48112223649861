import React, { useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";

import SideBar from "../SideBar";
import TopNav from "../TopNav";

import {
  useAppContext,
  AuthenticationState,
  handleNavState,
  SideBarState,
} from "../../libs";
export default function ResponsiveContainer(props) {
  const {
    authenticatedStatus,
    setNavState,
    setSideBarOpen,
    sideBarOpen,
    navState,
  } = useAppContext();
  let isAuthenticated =
    AuthenticationState.isAuthenticated(authenticatedStatus);

  const handleResize = useCallback(() => {
    setNavState(handleNavState(sideBarOpen, window.innerWidth));
  }, [setNavState, sideBarOpen]);

  useEffect(() => {
    const abortController = new AbortController();
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      abortController.abort();
    };
  }, [handleResize]);

  function openSideBar(fromLink = false) {
    if (fromLink && SideBarState.isOnDesktop(navState)) return;
    setSideBarOpen(!sideBarOpen);
  }

  function unAuthenticatedView() {
    return (
      <Col xs={12} id="page-content" className="p-0">
        <>{props.children}</>
      </Col>
    );
  }

  function authenticatedView() {
    const pageSizes = SideBarState.getStatePageSizes(navState);
    let topNav = (
      <Col xs={12} id="top-nav" className="p-0">
        <TopNav openSideBar={openSideBar} navState={navState}></TopNav>
      </Col>
    );
    let sidebar = SideBarState.showSideBar(navState) ? (
      <Col xs={pageSizes.sideBar} id="sidebar" className="sidebar">
        <SideBar openSideBar={openSideBar}></SideBar>
      </Col>
    ) : (
      <></>
    );
    let pageContent = SideBarState.showPageContent(navState) ? (
      <Col xs={pageSizes.pageContent} id="page-content" className="p-0">
        <>{props.children}</>
      </Col>
    ) : (
      <></>
    );

    return (
      <>
        {topNav}
        {sidebar}
        {pageContent}
      </>
    );
  }

  function renderView() {
    let view = isAuthenticated ? authenticatedView() : unAuthenticatedView();

    return (
      <>
        <Container fluid>
          <Row>{view}</Row>
        </Container>
      </>
    );
  }

  const mainView = renderView();
  return mainView;
}
