import { sendEmail } from "./emailService";
import { config as _config } from "../_config";

const emailTypes = {
  userFileCreated: "userFileCreated",
  inspectorFileCreated: "inspectorFileCreated",
  userReminder: "userReminder",
  inspectorReminder: "inspectorReminder",
};

const emailTypeOptions = [
  { value: emailTypes.userFileCreated, label: "User File Created" },
  { value: emailTypes.inspectorFileCreated, label: "Inspector File Created" },
  { value: emailTypes.userReminder, label: "User Reminder" },
  { value: emailTypes.inspectorReminder, label: "Inspector Reminder" },
];

const sendUserFileCreatedEmail = async () => {
  let path = _config.emailUserFileCreatedEndpoint;   
  let result = await sendEmail(path);

  return result;
};

const sendInspectorFileCreatedEmail = async () => {
  let path = _config.emailInspectorFileCreatedEndpoint;  
  let result = await sendEmail(path);

  return result;
};

export {
  emailTypes,
  emailTypeOptions,
  sendUserFileCreatedEmail,
  sendInspectorFileCreatedEmail
};
