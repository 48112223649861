import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import "./LoaderButton.scss";

import { LoadingState, DisabledState } from "../libs";

export default function LoaderButton({
  loadingStatus,
  className = "",
  variant = "",
  disabledStatus = DisabledState.NotDisabled,
  ...props
}) {
  return (
    <Button
      variant={variant}
      className={`loader-button ${className}`}
      disabled={
        DisabledState.isDisabled(disabledStatus) ||
        LoadingState.isPending(loadingStatus)
      }
      {...props}
    >
      {LoadingState.isPending(loadingStatus) && (
        <FAIcon icon={faSyncAlt} className="spinning" />
      )}
      {props.children}
    </Button>
  );
}
