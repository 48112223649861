import React from "react";

export default function HistoryIcon({ className = "" }) {
  return (
    <svg
      width="24px"
      height="20px"
      viewBox="0 0 24 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>history</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Main-Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="History"
          className="history"
          transform="translate(-20.000000, -108.000000)"
          fill="#000000"
          fillRule="nonzero"
        >
          <g id="history" transform="translate(20.000000, 108.000000)">
            <path
              d="M7.09986143,15.6439261 L5.9913164,16.625358 C6.34429561,17.0224942 6.72775982,17.3914088 7.13829099,17.7287298 L8.07538106,16.5832333 C7.72586605,16.2964434 7.39967667,15.9823557 7.09986143,15.6439261 Z"
              id="Path"
            ></path>
            <path
              d="M5.64323326,13.3573672 L4.28489607,13.9404619 C4.49491917,14.4298845 4.74429561,14.9014319 5.03057737,15.3505312 L6.27732102,14.558291 C6.03418014,14.1755658 5.82212471,13.7739954 5.64323326,13.3573672 Z"
              id="Path"
            ></path>
            <path
              d="M18.4570439,1.37473441 C16.9374134,0.493672055 15.2121016,0.0294226328 13.4555196,0.028960739 C8.45944573,0.0369976905 4.23852194,3.73690531 3.57616628,8.68891455 L1.04498845,6.15773672 L0,7.20272517 L4.21764434,11.4203695 L8.43528868,7.20272517 L7.39030023,6.15773672 L5.10448037,8.44060046 C5.97297921,3.83284065 10.4123788,0.801616628 15.0201386,1.67011547 C19.6278984,2.53861432 22.6591224,6.97801386 21.7906236,11.5857275 C20.9221247,16.1934411 16.4827252,19.2247575 11.8749654,18.3562125 C10.9304388,18.1781986 10.0230023,17.8410624 9.1913164,17.3591686 L8.44859122,18.6369515 C13.2154273,21.4006928 19.3201848,19.7768591 22.0839261,15.0100231 C24.8477136,10.2432333 23.2238799,4.13847575 18.4570439,1.37473441 Z"
              id="Path"
            ></path>
            <polygon
              id="Path"
              points="12.3469746 3.35459584 12.3469746 11.4839261 15.5987067 13.9227252 16.4855427 12.7402771 13.8250346 10.7448961 13.8250346 3.35459584"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
}
