import React from "react";
import ResponsiveContainer from "./ResponsiveContainer";

export default function HomepageLayout(props) {
  return (
    <ResponsiveContainer>
      <div>{props.children}</div>
    </ResponsiveContainer>
  );
}
