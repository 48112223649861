import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";

import { config } from "../../_config";
import { LoaderButton, Errors } from "../../components";
import { LoadingState, onError } from "../../libs";
import { getFile, downloadAttachment } from "./services";

import {
  genTextInput,
  genFileModalBody,
  genSelectField,
  genAttachmentField,
} from "./helpers";
import "./AddFile.scss";

export default function ViewFileModal(props) {
  const { fileId: _fileId } = props;
  const fileId = useMemo(() => _fileId, [_fileId]);

  const [loadingStatus, setLoadingStatus] = useState(LoadingState.Idle);
  const initial = {
    FileType_c: "",
    Date_c: "",
    RecordName: "",
    WasteCompany_c: "",
    WasteDocumentNo_c: "",
    WasteQuantity_c: "",
    files: [],
  };

  const [fields, handleFieldChange] = useState(initial);
  const [errorMessage, setErrorMessage] = useState([]);
  const formId = "details-file-form";

  const getFileValueByKey = useCallback((file, key) => {
    const getFileValue = (file, key, value) => {
      return file.data[key];
    };
    const getFileValueByDate_c = (file, key, value) => {
      if (key === "Date_c") {
        value = new Date(value);
      }

      return value;
    };

    let value = null;
    const valueGetters = [getFileValue, getFileValueByDate_c];
    for (const valueGetter of valueGetters) {
      value = valueGetter(file, key, value);
    }

    return value;
  }, []);

  const getAttachments = useCallback(async (file) => {
    let attachments = file.data.Attachment;
    const promises = attachments.map((item) =>
      downloadAttachment(file.data.Id, item)
    );
    return await Promise.all(promises);
  }, []);

  const loadData = useCallback(
    async (id) => {
      try {
        setLoadingStatus(LoadingState.Pending);
        let file = await getFile(id);

        const attachments = await getAttachments(file);
        handleFieldChange((fields) => {
          let fieldsToUpdate = {};
          for (const key in file.data) {
            if (Object.keys(fields).includes(key)) {
              fieldsToUpdate[key] = getFileValueByKey(file, key);
            }
          }

          return {
            ...fields,
            ...fieldsToUpdate,
            files: attachments,
          };
        });

        setLoadingStatus(LoadingState.Resolved);
      } catch (error) {
        let message = onError(error);
        setErrorMessage((errorMessage) => [...errorMessage, message]);
        setLoadingStatus(LoadingState.Idle);
      }
    },
    [getFileValueByKey, getAttachments]
  );

  useEffect(() => {
    if (fileId !== undefined) {
      setErrorMessage([]);
      loadData(fileId);
    }
  }, [fileId, loadData]);

  const onClickCancel = () => {
    handleFieldChange(initial);
    setErrorMessage([]);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onClickCancel}
      animation={true}
      size={"lg"}
      aria-labelledby={"contained-modal-title-vcenter"}
      centered
    >
      <Modal.Header closeButton className="text-center">
        <Modal.Title id="container-modal-title-vcenter">View File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="AddFile">
          <Container fluid className="p-0">
            <Row>
              <Col>
                <Form id={formId} className="p-0" noValidate>
                  <Errors messages={errorMessage}></Errors>
                  {genSelectField(fields, handleFieldChange, [], true)}
                  {genTextInput(
                    "RecordName",
                    "File Name",
                    fields,
                    handleFieldChange,
                    "text",
                    true
                  )}
                  {genFileModalBody(fields, handleFieldChange, config)}
                  {genAttachmentField(
                    fields,
                    handleFieldChange,
                    "Attachment",
                    true
                  )}
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <LoaderButton
          type="reset"
          loadingStatus={loadingStatus}
          variant="outline-success"
          onClick={onClickCancel}
          size={"sm"}
          className={"btn-modal"}
        >
          Cancel
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
