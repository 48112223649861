/* global FOG_API_URL, EMAIL_URL */ // The above commented line is a directive for JSLint and is required to build the app successfully without resolving those variables The config values are available globally, from config.js which is an external file added after the build and included as a script in public/index.html global.FOG_API_URL = process.env.REACT_APP_FOG_API_URL

global.FOG_API_URL = process.env.REACT_APP_FOG_API_URL
global.EMAIL_URL = process.env.REACT_APP_EMAIL_URL

const environmentConfig = {
  fogAccountsEndpoint: `${FOG_API_URL}/accounts`,
  fogFilesEndPoint: `${FOG_API_URL}/Files_c`,
  fogEducationEndPoint: `${FOG_API_URL}/Documents_c`,
  emailUserFileCreatedEndpoint: `${EMAIL_URL}/senduserfilecreatedemail`,
  emailUserReminderEndpoint: `${EMAIL_URL}/senduserreminderemail`,
  emailInspectorFileCreatedEndpoint: `${EMAIL_URL}/sendinspectorfilecreatedemail`,
  emailInspectorReminderEndpoint: `${EMAIL_URL}/sendinspectorreminderemail`,
};

let dateFormat = "dd/MM/yyyy";
export const config = {
  ...environmentConfig,
  dateFormat,
};
