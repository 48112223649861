import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Col } from "react-bootstrap";
import FieldValidation from "./FieldValidation";
import { testIdPrefix } from "./Field.test.helper";
import moment from "moment";

export default function DateField(props) {
  const controlProps = { ...props };
  const inLine = controlProps.inLine;
  delete controlProps.inLine;
  const id = controlProps.id;
  delete controlProps.id;
  const dataTestid = `${testIdPrefix}-${id}`;
  const ignoreDST = controlProps.ignoreDST;
  delete controlProps.ignoreDST;
  const genDataPicker = () => {
    if (controlProps.className === undefined) {
      controlProps.className = "";
    }
    let classNames = ["form-control", controlProps.className];
    if (controlProps.isInvalid) {
      classNames.push("is-invalid");
    }
    controlProps.className = classNames.join(" ");
    delete controlProps.inputGroupAppend;
    delete controlProps.handleChange;
    function onChange(value) {
      let valueResult = convertDateFromDST(value);
      var event = {
        target: {
          id: props.id,
          value: valueResult,
        },
      };
      props.handleChange(event);
    }
    return (
      <DatePicker
        id={id}
        {...controlProps}
        onChange={onChange}
        onFocus={(e) => (e.target.readOnly = true)}
      />
    );
  };

  const genLabelAndData = () => {
    return (
      <Form.Group data-testid={dataTestid} className={props.groupClass}>
        <Form.Label htmlFor={id}>{props.label}</Form.Label>
        {genDataPicker()}
        <FieldValidation messages={props.isInvalid}></FieldValidation>
      </Form.Group>
    );
  };
  const genLabelAndDataInLine = () => {
    return (
      <Form.Group className={props.groupClass}>
        <Form.Label htmlFor={id} column sm={2}>
          {props.label}
        </Form.Label>
        <Col sm={10}>{genDataPicker()}</Col>
        <Col>
          <FieldValidation messages={props.isInvalid}></FieldValidation>
        </Col>
      </Form.Group>
    );
  };

  let renderData;
  if (inLine) {
    renderData = genLabelAndDataInLine();
  } else {
    renderData = genLabelAndData();
  }

  return <>{renderData}</>;

  function convertDateFromDST(value) {
    let valueResult = value;
    if (ignoreDST) {
      let dataMoment = moment.parseZone(value).local(true);
      if (dataMoment.isDST()) {
        dataMoment = dataMoment.utcOffset(0, true);
      }
      valueResult = dataMoment.utc().toDate();
    }
    return valueResult;
  }
}
