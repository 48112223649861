export const constraints = {
  code: {
    presence: true,
    numericality: true,
    length: { is: 6 },
  },
  newPassword: {
    presence: true,
    length: {
      minimum: 10,
      message: "must be at least 10 characters",
    },
  },
  newPasswordCheck: {
    presence: true,
    length: {
      minimum: 10,
      message: "must be at least 10 characters",
    },
    equality: {
      attribute: "newPassword",
    },
  },
};
