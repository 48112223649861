export const constraints = {
  username: {
    presence: true,
  },
  password: {
    presence: true,
    length: {
      minimum: 10,
      message: "must be at least 10 characters",
    },
  },
};
