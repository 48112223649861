import SideBarState from "./sideBarStateLib";
import Variables from "../css/Variables.module.scss";

const onMobileDevice = (screenWidth) => {
  return screenWidth < Variables.screenSm;
};

const getNavState = (sideBarOpen, isMobile) => {
  let navState;
  if (sideBarOpen) {
    navState = isMobile ? SideBarState.OpenMobile : SideBarState.OpenDesktop;
  } else {
    navState = isMobile
      ? SideBarState.ClosedMobile
      : SideBarState.ClosedDesktop;
  }

  return navState;
};

const handleNavState = (sideBarOpen, screenWidth) => {
  let isMobile = onMobileDevice(screenWidth);
  return getNavState(sideBarOpen, isMobile);
};

export { onMobileDevice, handleNavState };
