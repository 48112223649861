import { config as _config } from ".";

const setAxiosEclipseAuthorizationHeaderValue = (axiosInstance) => {
  const token = Buffer.from(
    `${_config.eclipseUsername}:${_config.eclipsePassword}`,
    "utf8"
  ).toString("base64");

  axiosInstance.defaults.headers.common["Authorization"] = `Basic ${token}`;
};

export { setAxiosEclipseAuthorizationHeaderValue};
