import React from "react";

import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import FieldValidation from "./FieldValidation";
import { testIdPrefix } from "./Field.test.helper";

export default function TextInput(props) {
  const id = props.id;
  const label = `label_${id}`;
  const dataTestid = `${testIdPrefix}-${id}`;

  const renderSelect = () => {
    const controlProps = { ...props };
    delete controlProps.id;

    let select = props.loadOptions ? (
      <AsyncPaginate
        id={id}
        aria-labelledby={label}
        {...controlProps}
        onChange={controlProps.handleChange}
      ></AsyncPaginate>
    ) : (
      <Select
        id={id}
        aria-labelledby={label}
        {...controlProps}
        onChange={controlProps.handleChange}
      ></Select>
    );

    const validationStyles = {
      display: "block",
    };

    return (
      <>
        {select}
        <FieldValidation
          messages={props.isInvalid}
          styles={validationStyles}
        ></FieldValidation>
      </>
    );
  };

  const renderFormControl = () => {
    const controlProps = { ...props };
    delete controlProps.inputGroupAppend;
    delete controlProps.handleChange;
    return (
      <>
        <Form.Control {...controlProps} onChange={props.handleChange} />
        <FieldValidation messages={props.isInvalid}></FieldValidation>
      </>
    );
  };

  const renderInputGroup = (appendHtml) => {
    const controlProps = { ...props };
    delete controlProps.inputGroupAppend;
    delete controlProps.handleChange;

    return (
      <InputGroup>
        <Form.Control {...controlProps} onChange={props.handleChange} />
        {appendHtml}
        <FieldValidation messages={props.isInvalid}></FieldValidation>
      </InputGroup>
    );
  };

  const renderControl = () => {
    let control;
    if (props.type === "select") {
      control = renderSelect();
    } else {
      control = props.inputGroupAppend
        ? renderInputGroup(props.inputGroupAppend)
        : renderFormControl();
    }

    return control;
  };

  return (
    <Form.Group data-testid={dataTestid}>
      <Form.Label id={label} htmlFor={id}>
        {props.label}
      </Form.Label>
      {renderControl()}
    </Form.Group>
  );
}
