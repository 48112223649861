class StoreParams {

    set = function (key, value) {
        localStorage.setItem(key, value);
    }

    get = function (key) {
        return localStorage.getItem(key);
    }

    deleteKey = function (key) {
        localStorage.removeItem(key);
    }
    clear = function () {
        localStorage.clear();
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new StoreParams();