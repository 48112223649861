import Store from "./storeLib";

class EclipseApiLib {
  getList = function (offset, limit) {
    var data = JSON.parse(Store.get("data"));
    if (data == null || offset > data.length) return [];
    var newLimit = offset + limit;
    if (newLimit > data.length) newLimit = data.length;
    return data.slice(offset, newLimit);
  };

  count = function () {
    var data = JSON.parse(Store.get("data"));
    if (data == null) return 0;
    return data.length;
  };
  get = function (id) {};

  insert = function (fields) {
    var data = Store.get("data");
    var dataParse = [];
    if (data !== null) {
      dataParse = JSON.parse(data);
    }
    dataParse.push(fields);
    Store.set("data", JSON.stringify(dataParse));
  };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new EclipseApiLib();
