import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  useAppContext,
  AuthenticationState as AuthenticatedState,
} from "../libs";

export default function AuthenticatedRoute({
  component: Component,
  props: cProps,
  ...rest
}) {
  const { authenticatedStatus } = useAppContext();

  return (
    <Route
      {...rest}
      render={(props) =>
        AuthenticatedState.isAuthenticated(authenticatedStatus) ? (
          <Component {...props} {...cProps} />
        ) : (
          <Redirect to="/login" />
        )
      }
    ></Route>
  );
}
