import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";

import { config } from "../../_config";
import { LoaderButton, Errors } from "../../components";
import { LoadingState, useFormFields, onError, AuthStore, DisabledState } from "../../libs";
import {
  getDisabledStatus,
  isValidFormValidation,
  genFileModalBody,
  genSelectField,
  genAttachmentField,
} from "./helpers";
import { addFile as constraints } from "../../validation";
import { addFile } from "./services";
import { emails } from "../../emails";
import "./AddFile.scss";

const FILE_SIZE_LIMIT = 6;

const { sendUserFileCreatedEmail, sendInspectorFileCreatedEmail } = emails;

export default function AddFileModal(props) {
  const [loadingStatus, setLoadingStatus] = useState(LoadingState.Idle);
  const [fields, handleFieldChange, resetForm] = useFormFields({
    FileType_c: "",
    Date_c: "",
    WasteCompany_c: "",
    WasteDocumentNo_c: "",
    WasteQuantity_c: "",
    files: [],
  });
  const [validationResults, setValidationResults] = useState({});
  const [validated, setValidated] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(true);

  const formId = "add-file-form";

  const onSubmit = (event) => {
    event.preventDefault();
    setLoadingStatus(LoadingState.Pending);
    tryAddFile();
  };

  const tryAddFile = async () => {
    try {
      let results = isValidFormValidation(fields, constraints);
      setValidated(results.valid);
      setValidationResults(results.results);

      if (results.valid) {
        const user = await AuthStore.currentAuthenticatedUser();
        await getUserAttributeAndAddNewFile(user);
        sendFileCreatedEmails();
        props.onSubmit();
        resetForm();
        setValidated(false);
        setLoadingStatus(LoadingState.Resolved);
        props.onHide();
      } else {
        setLoadingStatus(LoadingState.Idle);
      }
    } catch (error) {
      let message = onError(error);
      setValidationResults({ addFile: [message] });
      setLoadingStatus(LoadingState.Idle);
    }
  };

  


  useEffect(() => {
    if (fields.files) {
      const errors = [];
      let isValidSize = true;

      fields.files.forEach(file => {
        if (isExcededFileSize(file)) {
          errors.push(`The file ${file.metadata.name} exceeds the ${FILE_SIZE_LIMIT} MB size limit.`);
          isValidSize = false;
        }
      });

      setValidationResults({ addFile: errors });
      setIsValidFileSize(isValidSize);
    }
  }, [fields]);

  const isExcededFileSize = (file) => {
    return file.metadata.size > FILE_SIZE_LIMIT * 1024 * 1024;
  };

  function sendFileCreatedEmails() {
    Promise.all([sendUserFileCreatedEmail(), sendInspectorFileCreatedEmail()]);
  }

  async function getUserAttributeAndAddNewFile(user) {
    setLoadingStatus(LoadingState.Pending);
    await addNewFile(user);
  }

  async function addNewFile(user) {
    await addFile(user.attributes, fields);
  }

  const onClickCancel = () => {
    resetForm();
    setValidationResults({});
    props.onHide();
    setValidated(false);
  };

  const getDisabledStatusWrapper = (fields) => {
    if (!isValidFileSize) {
      return DisabledState.Disabled;
    }

    return getDisabledStatus(fields);
  };

  return (
    <Modal
      show={props.show}
      onHide={onClickCancel}
      animation={true}
      size={"lg"}
      aria-labelledby={"contained-modal-title-vcenter"}
      centered
    >
      <Modal.Header closeButton className="text-center">
        <Modal.Title id="container-modal-title-vcenter">Add File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="AddFile">
          <Container fluid className="p-0">
            <Row>
              <Col>
                <Form
                  id={formId}
                  onSubmit={onSubmit}
                  className="p-0"
                  noValidate
                  validated={validated}
                >
                  <Errors messages={validationResults.addFile}></Errors>
                  {genSelectField(
                    fields,
                    handleFieldChange,
                    validationResults,
                    false
                  )}
                  {genFileModalBody(
                    fields,
                    handleFieldChange,
                    config,
                    false,
                    validationResults
                  )}
                  {genAttachmentField(
                    fields,
                    handleFieldChange,
                    "Add Attachment",
                    false
                  )}
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <LoaderButton
          type="reset"
          loadingStatus={loadingStatus}
          variant="outline-success"
          onClick={onClickCancel}
          size={"sm"}
          className={"btn-modal"}
        >
          Cancel
        </LoaderButton>
        <LoaderButton
          type="submit"
          form={formId}
          loadingStatus={loadingStatus}
          variant="success"
          disabledStatus={getDisabledStatusWrapper(fields)}
          size={"sm"}
          className={"btn-modal"}
        >
          Add
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
