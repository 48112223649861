import React from "react";

export default function UserIcon({ className = "" }) {
  return (
    <svg
      width="24px"
      height="28px"
      viewBox="0 0 24 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>User Icon</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Main-user"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Login"
          className="user"
          transform="translate(-501.000000, -452.000000)"
          fill="#000000"
        >
          <g id="Group-5" transform="translate(501.000000, 452.000000)">
            <path
              d="M7.43915866,6.25115556 C7.43915866,6.23393592 7.44509147,6.21635452 7.44509147,6.20477829 C7.63617158,2.05477313 10.5822491,1.60908837 11.8441302,1.60908837 L11.9133705,1.60908837 C13.4763059,1.64381705 16.1329778,2.28058191 16.3124093,6.20477829 C16.3124093,6.22228734 16.3124093,6.23957933 16.3183421,6.25115556 C16.3239855,6.29152765 16.7292982,10.227662 14.8885333,12.2998067 C14.1593757,13.1217189 13.1869003,13.5266698 11.9077271,13.5383183 L11.8497736,13.5383183 C10.5766057,13.5266698 9.59812506,13.1217189 8.87461085,12.2998067 C7.03985116,10.2392382 7.43351525,6.28588424 7.43915866,6.25115556 M11.7861767,15.1011814 L11.971324,15.1011814 C13.667386,15.0723855 15.039169,14.4760651 16.0520889,13.3359514 C18.2802956,10.823693 17.9100734,6.51740879 17.8694119,6.10645271 C17.724709,3.02131576 16.266032,1.54549147 15.0623938,0.856705943 C14.1650191,0.341491473 13.1173705,0.0636620155 11.9481716,0.0405095607 L11.8497736,0.0405095607 C11.2073654,0.0405095607 9.94548424,0.144767959 8.73584083,0.833553488 C7.5202646,1.52226667 6.03850749,2.99816331 5.89380465,6.10645271 C5.85336021,6.51740879 5.48284858,10.823693 7.7113447,13.3359514 C8.71833178,14.4760651 10.0901871,15.0723855 11.7861767,15.1011814"
              id="Fill-1"
            ></path>
            <path
              d="M23.7733747,22.6634065 L23.7733747,22.6474444 C23.7733747,22.6043868 23.7674419,22.5613964 23.7674419,22.5133087 C23.7329302,21.4509544 23.6574677,18.9668293 21.1455711,18.1727463 C21.1282791,18.1672467 21.1051266,18.1620154 21.0876176,18.1565158 C18.477323,17.5394919 16.3067804,16.1444811 16.2834832,16.1284519 C15.9306253,15.8976045 15.4442067,15.9780188 15.1954625,16.3052427 C14.9465013,16.632802 15.0332506,17.0834307 15.3862532,17.314144 C15.4848682,17.3783279 17.7885375,18.8645509 20.6710181,19.5515266 C22.0194315,19.9968569 22.1700672,21.3327138 22.2104393,22.5561651 C22.2104393,22.6043868 22.2104393,22.6474444 22.2163721,22.6903007 C22.2220879,23.173189 22.1872868,23.9191174 22.0948941,24.3481502 C21.1571473,24.8417024 17.4816227,26.548109 11.8902326,26.548109 C6.32228424,26.548109 2.62360724,24.8364711 1.68021705,24.342986 C1.58746253,23.9136178 1.54709044,23.1676895 1.55866667,22.6848012 C1.55866667,22.6419448 1.56431008,22.5992226 1.56431008,22.5506655 C1.60504393,21.3275496 1.75539018,19.9913574 3.10402067,19.546027 C5.98650129,18.8593196 8.29017054,17.3679324 8.38849612,17.3086444 C8.74149871,17.0781994 8.82853747,16.6273024 8.57957623,16.3000114 C8.33061499,15.9727875 7.84448579,15.8924403 7.4914832,16.1228853 C7.46833075,16.1392498 5.30936434,17.5339923 2.68720413,18.1512846 C2.66397933,18.1565158 2.64675969,18.1620154 2.62925065,18.1672467 C0.117281654,18.9668293 0.0421085271,21.4509544 0.00737984496,22.5078092 C0.00737984496,22.5561651 0.00737984496,22.5992226 0.00166408269,22.6419448 L0.00166408269,22.6581752 C-0.00426873385,22.9371774 -0.0099121447,24.3696119 0.29678553,25.0885119 C0.354666667,25.2281472 0.45870801,25.3461195 0.597767442,25.4264667 C0.77126615,25.5339764 4.9332093,27.9912744 11.8961654,27.9912744 C18.8591214,27.9912744 23.0210646,25.5287451 23.1945633,25.4264667 C23.3276899,25.3461195 23.4375917,25.2281472 23.4955452,25.0885119 C23.7849509,24.3749103 23.7790181,22.9423416 23.7733747,22.6634065"
              id="Fill-3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
