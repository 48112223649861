import React from "react";
import { Image, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  useAppContext,
  AuthenticationState,
  AuthStore,
  SideBarState,
} from "../libs";
import { Svgs } from "../components";
import { NavBarTextLogo as TextLogo, NavBarLogo as Logo } from "../assets";
import "./TopNav.scss";

const { menu: MenuIcon } = Svgs;

export default function TopNav(props) {
  const { setUserAuthenticationStatus } = useAppContext();
  const history = useHistory();

  const signOut = async () => {
    await AuthStore.signOut();
    setUserAuthenticationStatus(AuthenticationState.NotAuthenticated);
    history.push("/login");
  };

  const getLogo = () => {
    let src = TextLogo;
    let className = "logo";
    if (SideBarState.isOnMobile(props.navState)) {
      src = Logo;
      className += " logo--mobile";
    }

    return <Image className={className} src={src}></Image>;
  };

  return (
    <div className="top-nav">
      {getLogo()}
      <div
        id="hamburger"
        className="hamburger"
        onClick={() => props.openSideBar(false)}
      >
        <MenuIcon className="svg white"></MenuIcon>
      </div>
      <Button className="log-out" variant="link" onClick={signOut}>
        Log Out
      </Button>
    </div>
  );
}
