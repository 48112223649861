import React from "react";
import { Svgs } from "../components";
const { education, history, twitter, phone, envelope, user } = Svgs;

const svgNames = {
  education: "education",
  history: "history",
  twitter: "twitter",
  envelope: "envelope",
  phone: "phone",
  user: "user",
};

const svgs = {
  education: education,
  history: history,
  twitter: twitter,
  phone: phone,
  envelope: envelope,
  user: user,
};

const create = (key, className) => {
  return React.createElement(svgs[key], {
    className: className,
  });
};

const SvgLib = {
  create,
  ...svgNames,
};

export default SvgLib;
