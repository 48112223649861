const stateEnum = {
  OpenMobile: 0,
  OpenDesktop: 1,
  ClosedMobile: 2,
  ClosedDesktop: 3,
};

const isOpenOnMobile = (sideBarState) =>
  sideBarState === SideBarState.OpenMobile;
const isOpenOnDesktop = (sideBarState) =>
  sideBarState === SideBarState.OpenDesktop;
const isClosedOnMobile = (sideBarState) =>
  sideBarState === SideBarState.ClosedMobile;
const isClosedOnDesktop = (sideBarState) =>
  sideBarState === SideBarState.ClosedDesktop;

const isOnMobile = (sideBarState) =>
  isOpenOnMobile(sideBarState) || isClosedOnMobile(sideBarState);
const isOnDesktop = (sideBarState) =>
  isOpenOnDesktop(sideBarState) || isClosedOnDesktop(sideBarState);

const statePageSizes = {
  [stateEnum.OpenMobile]: {
    sideBar: 12,
    pageContent: 0,
  },
  [stateEnum.OpenDesktop]: {
    sideBar: 3,
    pageContent: 9,
  },
  [stateEnum.ClosedMobile]: {
    sideBar: 0,
    pageContent: 12,
  },
  [stateEnum.ClosedDesktop]: {
    sideBar: 0,
    pageContent: 12,
  },
};

const showSideBarStates = [stateEnum.OpenMobile, stateEnum.OpenDesktop];
const showPageContentStates = [
  stateEnum.OpenDesktop,
  stateEnum.ClosedDesktop,
  stateEnum.ClosedMobile,
];

const showSideBar = (sideBarState) => showSideBarStates.includes(sideBarState);
const showPageContent = (sideBarState) =>
  showPageContentStates.includes(sideBarState);

const getStatePageSizes = (sideBarState) => statePageSizes[sideBarState];

const SideBarState = {
  ...stateEnum,
  isOpenOnMobile,
  isOpenOnDesktop,
  isClosedOnMobile,
  isClosedOnDesktop,
  isOnMobile,
  isOnDesktop,
  getStatePageSizes,
  showSideBar,
  showPageContent,
};

export default SideBarState;
