import { validate as _validate } from "validate.js";
import { utc } from "moment";

_validate.extend(_validate.validators.datetime, {
  parse: function (value, options) {
    return +utc(value);
  },
  format: function (value, options) {
    var format = options.dateOnly ? "dd/MM/yyyy" : "dd/MM/yyyy hh:mm:ss";
    return utc(value).format(format);
  },
});

const validate = (fields, constraints) => {
  let fieldsConstraints = {
    ...constraints,
  };
  let results = _validate(fields, fieldsConstraints);
  return {
    results: results ? results : {},
    valid: results ? false : true,
  };
};

_validate.validators.arrayString = (arrayString, constraints) => {
  let items = arrayString.split(constraints.delimeter);
  const results = items.reduce((errors, item, index) => {
    const result = _validate({ item: item }, { item: constraints.item });
    if (result) errors.push(result.item);
    return errors;
  }, []);

  return results.join(",");
};

export { validate };
