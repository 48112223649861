import Store from "./storeLib";
import { Auth } from "@aws-amplify/auth";
import CognitoUserCustom from "./CognitoUserCustomLib";
import { getAccount } from "./services/accountService";

const AUTHSTORE_KEY = "CognitoStep";
const FSE_ID = "custom:fse_id";
const USER_TYPE_ID = "custom:user_type_id";
const EMAIL = "email";
const SURNAME = "family_name";
const FIRST_NAME = "given_name";

const FSE_ID_DOMESTIC = "1";
const GROUP_ADMINISTRATOR = "Admin";
// const ATTRIBUTE_SUPPORT = "support";

class AuthStore {
  signIn = async function (username, password) {
    try {
      var user = await Auth.signIn(username, password);
      if (this.checkIfValueIsChallenge(user)) return null;
      return user;
    } catch (error) {
      throw error;
    }
  };

  completeSignUpWithCode = async function (username, code) {
    let result = await Auth.confirmSignUp(username, code);
    return this.checkIfValueIsChallenge(result);
  };

  completeForgotPassword = async function (username, code, password) {
    await Auth.forgotPasswordSubmit(username, code, password);
    return await this.signIn(username, password);
  };

  resendVerificationCode = async function (username) {
    try {
      await Auth.forgotPassword(username);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  completeSignUpWithNewPassword = async function (newPassword) {
    let user = await this.getCognitoUserStoreValue();
    let result = await Auth.completeNewPassword(user, newPassword);
    return this.checkIfValueIsChallenge(result);
  };

  isChallenge = function (value) {
    let storeValue = this.getCognitoUserStoreValue();
    return storeValue.challengeName === value;
  };

  signOut = async function () {
    await Auth.signOut();
    Store.clear();
  };

  currentSession = async function () {
    return await Auth.currentSession();
  };

  getAuthenticationToken = async function () {
    const currentSession = await Auth.currentSession();
    const cognitoIdToken = currentSession.getIdToken();
    return cognitoIdToken.jwtToken;
  };

  currentAuthenticatedUser = async function () {
    let cognitoUserCustom;
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (
        user !== null &&
        user.attributes[FSE_ID] !== undefined
      ) {
        cognitoUserCustom =
          await this.genCognitoUserCustomWithEclipsUserInformations(user);          
      } else {
        cognitoUserCustom = new CognitoUserCustom(user);
      }
    } catch (error) {
      return Promise.reject(error);
    }
    return Promise.resolve(cognitoUserCustom);
  };

  signUp = async function (username, email, fseId, userTypeId, userGroup) {
    return await Auth.signUp({
      username: username,
      password: this.passwordGenerator(),
      attributes: {
        [FSE_ID]: fseId,
        [USER_TYPE_ID]: userTypeId,
        [EMAIL]: email,
      },
      clientMetadata: {
        group: userGroup,
      },
    });
  };
  signUpPrimaryAccount = async function (
    firstName,
    surname,
    username,
    password,
    email,
    fseId
  ) {
    return await Auth.signUp({
      username: username,
      password: password,
      attributes: {
        [USER_TYPE_ID]: FSE_ID_DOMESTIC,
        [EMAIL]: email,
        [SURNAME]: surname,
        [FIRST_NAME]: firstName,
      },
      clientMetadata: {
        group: GROUP_ADMINISTRATOR,
        fseId: fseId,
      },
    });
  };

  genCognitoUserCustomWithEclipsUserInformations = async function (user) {
    let account = await getAccount(user.attributes[FSE_ID]);
    if (account == null || account.status !== 200) {
      return null;
    }

    let attributes = {
      support: {
        email: account.data.OrganizationDEO_SupportEmailAddress_c,
        phone: account.data.OrganizationDEO_SupportPhoneNumber_c,
      },
      partyId: account.data.PartyId,
      twitterHandle: account.data.OrganizationDEO_TwitterHandle_c,
      clientUrl: account.data.OrganizationDEO_ClientURL_c,
      inspectorEmail: account.data.OrganizationDEO_ClientContactEmail_c,
      fseReference: account.data.OrganizationDEO_FacilityID_c,
    };
    let cognitoUserCustom = new CognitoUserCustom(user, attributes);
    Store.set(
      user.userDataKey,
      JSON.stringify(cognitoUserCustom.getUserDataProperty())
    );
    return cognitoUserCustom;
  };

  getCognitoUserStoreValue () {
    let storeValue = JSON.parse(Store.get(AUTHSTORE_KEY));
    let newCognitoUser = new CognitoUserCustom(storeValue);
    return newCognitoUser;
  }

  checkIfValueIsChallenge (result) {
    let challengeDefined = result.challengeName !== undefined;
    if (challengeDefined) {
      Store.clear();
      Store.set(AUTHSTORE_KEY, JSON.stringify(result));
    }
    return challengeDefined;
  }

  //ECAS want temp password when creating account to then reset password on first login
  passwordGenerator (length = 10) {
    if (length < 10) {
      throw Error("Password Length cannot be less than 10");
    }

    let letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let numbers = "0123456789";
    let symbols = "!&*$£%#!#$%&\\()*+,-./:;<=>?@^[\\]^_`{|}~";
    let charset = letters + numbers + symbols;

    let generatedPassword = "";
    for (var i = 0; i < length; i++) {
      const index = Math.floor(Math.random() * charset.length);
      generatedPassword += charset[index];
    }

    return generatedPassword;
  }
}

export { FSE_ID, USER_TYPE_ID };
// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthStore();
