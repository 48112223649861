import React, { useEffect, useState } from "react";
import { Col, Row, Form, Figure } from "react-bootstrap";
import PreviewFileModal from "./PreviewFileModal";
import { addFilePreviewProperties } from "../../libs";

export default function UploadFileField(props) {
  const [preview, setGenPreview] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const imageModalInit = {
    name: "",
    streamPreview: "",
    file: null,
    streams: [],
  };
  const [imageModal, setImageModal] = useState(imageModalInit);
  const [filedownloadlink, setFiledownloadlink] = useState("");

  const formFileProps = getFormFileProps();

  function getFormFileProps() {
    const formFileProps = { ...props };
    delete formFileProps.inputGroupAppend;
    delete formFileProps.handleChange;
    delete formFileProps.id;
    delete formFileProps.value;
    delete formFileProps.className;
    delete formFileProps.label;
    delete formFileProps.groupClassName;

    return formFileProps;
  }
  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = function (e) {
          resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      } catch (e) {
        reject(e);
      }
    });
  }

  async function readFilesToUpload(filesToUpload, currentFiles) {
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      currentFiles = currentFiles.filter(
        (item) => item.metadata.name !== file.name
      );
      var fileStream = await readFileAsync(file);
      let fileParam = {
        metadata: file,
        stream: fileStream,
      };
      await addFilePreviewProperties(fileParam);
      currentFiles.push(fileParam);
    }
    return currentFiles;
  }

  async function uploadFiles(e) {
    let filesToUpload = e.target.files;
    let id = e.target.id;
    let files = await readFilesToUpload(filesToUpload, props.value);
    var storeEvent = {
      target: { id: id, value: files },
    };
    props.handleChange(storeEvent);
    setGenPreview(true);
  }

  function removeFile($index) {
    props.value.splice($index, 1);
    props.handleChange({
      target: {
        id: props.id,
        [props.id]: props.id,
        value: props.value,
      },
    });
    setGenPreview(true);
  }

  function genRemoveFile(index) {
    if (props.readOnly) return;
    return (
      <div className="figure-remove" onClick={() => removeFile(index)}></div>
    );
  }

  function genImage(file, index) {
    if (file.streamPreview === undefined) {
      setGenPreview(true);
      return;
    }
    return (
      <Col xs={6} sm={4} md={4} lg={2} key={`figure-${index}`}>
        <Figure>
          <Figure.Image
            id={"img" + index}
            src={file.streamPreview}
            onClick={(e) => onClickImage(e, file)}
          />
          <Figure.Caption className={"text-center"}>
            {file.metadata.name}
          </Figure.Caption>
          {genRemoveFile(index)}
        </Figure>
      </Col>
    );
  }

  useEffect(() => {
    async function genPreview() {
      for (let index = 0; index < props.value.length; index++) {
        const file = props.value[index];
        await addFilePreviewProperties(file);
      }
      setGenPreview(false);
    }
    genPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview]);

  const genAddAttachmentButton = () => {
    if (props.readOnly) return;
    return (
      <Col xs={6} sm={4} md={4} lg={2}>
        <Form.Label className={props.className} htmlFor={props.id}>
          {props.label}
        </Form.Label>
      </Col>
    );
  };

  const onClickModalButtonClose = (e) => {
    setShowModal(false);
    setImageModal(imageModalInit);
  };
  const onClickImage = function (e, file) {
    setShowModal(true);
    setImageModal({
      name: file.metadata.name,
      streamPreview: file.streamPreview,
      file: file,
      streams: file.streams,
    });
    genDownloadLink(file);
  };

  function genImages(files) {
    return files.map((data, index) => {
      return genImage(data, index);
    });
  }

  function genDownloadLink(file) {
    setFiledownloadlink(file.stream);
  }

  return (
    !preview && (
      <>
        <Form.Group className={props.groupClassName}>
          <Form.File
            id={props.id}
            className="invisible"
            multiple={true}
            {...formFileProps}
            onChange={uploadFiles}
          />
          <Row className="m-0">
            {genImages(props.value)}
            {genAddAttachmentButton()}
          </Row>
        </Form.Group>
        <PreviewFileModal
          show={showModal}
          onHide={onClickModalButtonClose}
          onClickModalButtonClose={onClickModalButtonClose}
          filedownloadlink={filedownloadlink}
          imageModal={imageModal}
        />
      </>
    )
  );
}
