import React from "react";
import TextInput from "./TextInput";

export default function SelectField(props) {
  const parentOnChange = props.handleChange;
  let onChange = (option) => {
    const values = mapValueForOnChange(option);
    let target = {
      id: props.id,
      value: values.join(","),
    };

    let event = {
      target: target,
    };
    parentOnChange(event);
  };

  const mapValueForOnChange = (option) => {
    if (props.isMulti) return option ? option.map((item) => item.value) : [];
    return option ? [option.value] : [""];
  };

  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 3;
    return bottomBorder < scrollTop;
  };

  const getValue = () => {
    const formValues = getValueArray();
    let value = props.options.filter((option) =>
      formValues.includes(option.value)
    );
    return value === undefined ? null : value;
  };

  const getValueArray = () =>
    props.isMulti ? props.value.split(",") : [props.value];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? "#ddd" : props.isInvalid ? "red" : "#ddd",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused
          ? "#ddd"
          : props.isInvalid
          ? "red"
          : "#ddd",
      },
    }),
  };

  let styles = {
    ...props.styles,
    ...customStyles,
  };

  const fieldProps = {
    ...props,
    type: "select",
    handleChange: onChange,
    shouldLoadMore,
    styles: styles,
    value: getValue(),
  };

  return <TextInput {...fieldProps}></TextInput>;
}
