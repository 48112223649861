import React from "react";

import { Form } from "react-bootstrap";

export default function FieldValidation(props) {
  return props.messages ? (
    props.messages.map((message, id) => {
      return (
        <Form.Control.Feedback
          key={`${props.id}-errors-${id}`}
          type="invalid"
          style={props.styles}
        >
          {message}
        </Form.Control.Feedback>
      );
    })
  ) : (
    <></>
  );
}
