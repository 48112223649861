import React from "react";
import { Link } from "react-router-dom";
import { Svgs as SvgLib } from "../../libs";

const mapToSupportLink = (menuItems) =>
  menuItems.map((menuItem) => {
    return (
      <a
        key={`support-item-${menuItem.id}`}
        className={"d-block item " + menuItem.className}
        href={menuItem.link}
      >
        {SvgLib.create(menuItem.svg, "svg green")}
        {menuItem.title}
      </a>
    );
  });

const mapToMenuLink = (menuItems, openSiderbar) =>
  menuItems.map((menuItem) => {
    return (
      <Link
        key={`menu-link-${menuItem.id}`}
        className="d-block item"
        to={menuItem.link}
        onClick={(event) => {
          openSiderbar(true);
          if (menuItem.onClick) menuItem.onClick(event);
        }}
      >
        {SvgLib.create(menuItem.svg, "svg grey-5")}
        {menuItem.title}
      </Link>
    );
  });

export { mapToSupportLink, mapToMenuLink };
