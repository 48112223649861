class ClientLinksGenerator {
  #sectionTitle = "Client Links";
  #linkProperty = "clientLinks";
  constructor(props) {
    this.props = props;
  }
  generate = async () => {
    const sectionIndex = this.getSectionIndex(this.#sectionTitle);
    if (!this.sectionExists(sectionIndex)) {
      this.pushNewSection();
    }

    return {
      property: this.#linkProperty,
      value: {
        length: this.getSectionLength(sectionIndex),
        hasMore: false,
      },
    };
  };

  getSectionLength(sectionIndex) {
    return this.sectionExists(sectionIndex) ? 0 : 1;
  }

  sectionExists(sectionIndex) {
    return sectionIndex > -1;
  }

  pushNewSection() {
    this.props.sections.splice(0, 0, {
      header: { Title: this.#sectionTitle },
      items: [
        {
          Url: this.props.user.attributes.clientUrl,
          Title: this.props.user.attributes.clientUrl,
        },
      ],
      order: 0,
    });
  }

  getSectionIndex(title) {
    return this.props.sections.findIndex(this.getSectionByTitle(title));
  }

  getSectionByTitle(title) {
    return (section) => section.header.Title === title;
  }
}

export default ClientLinksGenerator;
