import axiosEmail from "./axios-email";
import {
  emailTypeOptions,
  emailTypes,
  sendInspectorFileCreatedEmail,
  sendUserFileCreatedEmail,
} from "./emails";
import { sendEmail } from "./emailService";

const emails = {
  emailTypeOptions,
  emailTypes,
  sendInspectorFileCreatedEmail,
  sendUserFileCreatedEmail,
};

export { axiosEmail, sendEmail, emails };
