import {
  AuthStore,
  CognitoState,
  DisabledState,
  SignInState,
} from "../../libs";
import { validate } from "../../validation";

const isValidFormValidation = (fields, constraints) => {
  return validate(fields, constraints);
};

const isValidAuthSignIn = async (fields) => {
  let signIn = await AuthStore.signIn(fields.username, fields.password);
  let signInState =
    signIn != null ? SignInState.ValidSignIn : SignInState.InvalidSignIn;
  if (
    SignInState.isInvalidSignIn(signInState) &&
    AuthStore.isChallenge(CognitoState.NewPasswordRequired)
  ) {
    signInState = SignInState.ChangePassword; //ChangePassword;
  }
  return signInState;
};

const getDisabledStatus = (fields) => {
  let isValidForm = fields.username.length > 0 && fields.password.length > 0;
  return isValidForm ? DisabledState.NotDisabled : DisabledState.Disabled;
};

export { isValidFormValidation, isValidAuthSignIn, getDisabledStatus };
