import { getDocuments as globalLinks } from "../services";

class EclipseLinksGenerator {
  #sectionTitle =
    "Please identity your equipment from the list below. If in any doubt, please contact the Environmental Enforcement Officer looking after your case.";
  #pleaseSelect = "Please select";
  #linkProperty = "eclipseLinks";
  constructor(props) {
    this.props = props;
  }
  generate = async () => {
    const response = await globalLinks(this.props.offset, this.props.partyId);
    const sectionIndex = this.getSectionIndex(this.#sectionTitle);
    if (!this.sectionExists(sectionIndex)) {
      this.pushNewSection(response.data);
    }
    return {
      property: this.#linkProperty,
      value: {
        length: this.getSectionLength(sectionIndex),
        hasMore: false,
      },
    };
  };

  getSectionLength(sectionIndex) {
    return this.sectionExists(sectionIndex) ? 0 : 1;
  }

  sectionExists(sectionIndex) {
    return sectionIndex > -1;
  }

  pushNewSection(items) {
    this.props.sections.splice(0, 0, {
      header: { Title: this.#sectionTitle },
      items: items,
      order: 1,
    });
  }

  getSectionIndex(title) {
    return this.props.sections.findIndex(this.getSectionByTitle(title));
  }

  getSectionByTitle(title) {
    return (section) => section.header.Title === title;
  }
}

export default EclipseLinksGenerator;
