import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";

import {
  LoaderButton,
  Errors,
  PageHeaderWithLogo,
  FormFields,
} from "../../components";
import {
  onError,
  useFormFields,
  LoadingState,
  AuthStore,
  useAppContext,
  AuthenticationState as AuthenticatedState,
} from "../../libs";
import { BlueLogo as AppLogo } from "../../assets";
import { getDisabledStatus, isValidFormValidation } from "./helpers";
import { changePassword as constraints } from "../../validation";
import "./ChangePassword.scss";

const { TextInput, PasswordField } = FormFields;

export default function ChangePassword () {
  const history = useHistory();
  const { setUserAuthenticationStatus } = useAppContext();
  const [loadingStatus, setLoadingStatus] = useState(LoadingState.Idle);
  const [loadingStatusSendCode, setLoadingStatusSendCode] = useState(LoadingState.Idle);
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    newPassword: "",
    newPasswordCheck: "",
  });
  const { username } = useParams();
  const [validationResults, setValidationResults] = useState({});
  const [validated, setValidated] = useState(false);

  async function onSubmit (event) {
    event.preventDefault();
    setLoadingStatus(LoadingState.Pending);
    await tryChangePassword();
  }

  const tryChangePassword = async () => {
    try {
      let results = isValidFormValidation(fields, constraints);
      setValidated(results.valid);
      setValidationResults(results.results);

      if (results.valid) {
        await handleChangePasswordFlow(username, fields);
        setLoadingStatus(LoadingState.Resolved);
        setUserAuthenticationStatus(AuthenticatedState.Authenticated);
        history.push("/");
      } else {
        setLoadingStatus(LoadingState.Idle);
      }
    } catch (error) {
      let message = onError(error);
      setValidationResults({ changePassword: [message] });
      setLoadingStatus(LoadingState.Rejected);
    }
  };

  async function handleChangePasswordFlow (username, fields) {
    if (username !== undefined) {
      await AuthStore.completeForgotPassword(
        username,
        fields.code,
        fields.newPassword
      );
    } else {
      await AuthStore.completeSignUpWithNewPassword(fields.newPassword);
    }
  }

  const sendCode = () => {
    setLoadingStatusSendCode(LoadingState.Pending)
    AuthStore.resendVerificationCode(username).then(() => {
      setLoadingStatusSendCode(LoadingState.Resolved)
    }
    ).catch((error) => {
      setLoadingStatusSendCode(LoadingState.Rejected)
    })
  }

  function CodeField () {
    if (username !== undefined)
      return (
        <TextInput
          id="code"
          name="code"
          label="Insert Verification Code"
          type="text"
          placeholder="123456"
          value={fields.code}
          handleChange={handleFieldChange}
          isInvalid={validationResults.code}
        ></TextInput>
      );
  }

  const renderHeader = () => {
    let title =
      username === undefined ? "Change Password" : "Verification Code";
    return (
      <PageHeaderWithLogo logo={AppLogo} title={title}></PageHeaderWithLogo>
    );
  };

  return (
    <div className="ChangePassword">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8} lg={6} className="p-0">
            {renderHeader()}
            <Form
              className="form form--changePassword"
              onSubmit={onSubmit}
              noValidate
              validated={validated}
            >
              <Errors messages={validationResults.changePassword}></Errors>
              {CodeField()}
              <PasswordField
                id="newPassword"
                name="newPassword"
                label="New Password:"
                value={fields.newPassword}
                handleChange={handleFieldChange}
                isInvalid={validationResults.newPassword}
              ></PasswordField>
              <PasswordField
                id="newPasswordCheck"
                name="newPasswordCheck"
                label="Confirm New Password: "
                value={fields.newPasswordCheck}
                handleChange={handleFieldChange}
                isInvalid={validationResults.newPasswordCheck}
              ></PasswordField>
              <LoaderButton
                block
                type="submit"
                size="large"
                loadingStatus={loadingStatus}
                variant="primary"
                disabledStatus={getDisabledStatus(fields)}
              >
                Submit
              </LoaderButton>
              <LoaderButton
                block
                type="button"
                size="large"
                loadingStatus={loadingStatusSendCode}
                variant="secondary"
                onClick={sendCode}
              >
                Resend Code
              </LoaderButton>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
