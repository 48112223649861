const stateEnum = {
  Authenticated: 0,
  NotAuthenticated: 1,
};

const isAuthenticated = (authenticatedState) =>
  authenticatedState === stateEnum.Authenticated;
const isNotAuthenticated = (authenticatedState) =>
  authenticatedState === stateEnum.NotAuthenticated;

const AuthenticatedState = {
  ...stateEnum,
  isAuthenticated,
  isNotAuthenticated,
};

export default AuthenticatedState;
