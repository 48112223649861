import AuthStore, { FSE_ID } from "../../libs/authStoreLib";
import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { useFormFields } from "../../libs/hooksLib";
import LoadingState from "../../libs/loadingStateLib";
import {
  getDisabledStatus,
  userTypeOptions,
  isValidFormValidation,
  userGroupOptions,
} from "./helpers";

import { constraints } from "../../validation/createAccount";

import {
  TextInput,
  EmailField,
  SelectField,
} from "../../components/FormFields";
import Errors from "../../components/Errors";
import AppLogo from "../../assets/logo-only-blue.png";

import "./CreateAccount.scss";
import PageHeaderWithLogo from "../../components/PageHeaderWithLogo/PageHeaderWithLogo";

export default function CreateAccount() {
  const history = useHistory();
  const [loadingStatus, setLoadingStatus] = useState(LoadingState.Idle);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    username: "",
    fseId: "",
    userTypeId: "",
    userGroup: "",
  });
  const [validationResults, setValidationResults] = useState({});
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingStatus(LoadingState.Pending);
    await tryCreateAccount();
  };

  const tryCreateAccount = async () => {
    try {
      let results = isValidFormValidation(fields, constraints);
      setValidated(results.valid);
      setValidationResults(results.results);

      if (results.valid) {
         let currentAuthenticatedUser =
           await AuthStore.currentAuthenticatedUser();

        await AuthStore.signUp(
          fields.username,
          fields.email,
          currentAuthenticatedUser.attributes[FSE_ID],
          fields.userTypeId,
          fields.userGroup
        );
        setLoadingStatus(LoadingState.Resolved);
        history.push("/");
      } else {
        setLoadingStatus(LoadingState.Idle);
      }
    } catch (error) {
      let message = onError(error);
      setValidationResults({ createAccount: [message] });
      setLoadingStatus(LoadingState.Rejected);
    }
  };

  return (
    <div className="CreateAccount">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={6} lg={8} className="p-0">
            <PageHeaderWithLogo
              logo={AppLogo}
              title={"Create New Account"}
            ></PageHeaderWithLogo>
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
              <Errors messages={validationResults.createAccount}></Errors>
              <SelectField
                id="userTypeId"
                name="userTypeId"
                placeholder="Enter User Type"
                label="User Type"
                handleChange={handleFieldChange}
                options={userTypeOptions}
                isInvalid={validationResults.userTypeId}
                value={fields.userTypeId}
                isClearable
              ></SelectField>
              <TextInput
                id="username"
                name="username"
                type="text"
                placeholder="Enter Username"
                label="Username"
                value={fields.username}
                handleChange={handleFieldChange}
                isInvalid={validationResults.username}
              ></TextInput>
              <EmailField
                id="email"
                name="email"
                label="Enter Business Email"
                placeholder="example@email.com"
                value={fields.email}
                handleChange={handleFieldChange}
                isInvalid={validationResults.email}
              ></EmailField>
              <SelectField
                id="userGroup"
                name="userGroup"
                placeholder=""
                label="Role"
                handleChange={handleFieldChange}
                options={userGroupOptions}
                isInvalid={validationResults.userGroup}
                value={fields.userGroup}
                isClearable
              ></SelectField>
              <LoaderButton
                block
                type="submit"
                size="large"
                loadingStatus={loadingStatus}
                disabledStatus={getDisabledStatus(fields)}
                variant={"primary"}
              >
                Create Account
              </LoaderButton>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
