import { DisabledState, User, Admin } from "../../libs";
import { getApprovedFses } from "../../libs/services/accountService";
import { validate } from "../../validation/helpers";

const userTypeOptions = [
  { value: "1", label: "Domestic" },
  { value: "2", label: "Commerical" },
];

const userGroupOptions = [
  { value: Admin, label: "Administrator" },
  { value: User, label: User },
];

const mapFsesToOptions = (fses) => {
  return fses.map((i) => {
    return { label: i.OrganizationName, value: i.PartyNumber };
  });
};

const loadFses = async (inputValue, loadedOptions) => {
  let fses = await getApprovedFses(inputValue, loadedOptions.length);
  let options = mapFsesToOptions(fses.data.items);

  return { options, hasMore: fses.data.hasMore };
};

const getDisabledStatus = (fields) => {
  let isValidForm =
    fields.email.length > 0 &&
    fields.username.length > 0 &&
    fields.userGroup.length > 0 &&
    fields.userTypeId.length > 0;
  return isValidForm ? DisabledState.NotDisabled : DisabledState.Disabled;
};

const isValidFormValidation = (fields, constraints) => {
  return validate(fields, constraints);
};

export {
  userTypeOptions,
  loadFses,
  getDisabledStatus,
  isValidFormValidation,
  userGroupOptions,
};
