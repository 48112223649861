import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AuthStore, onError, useFormFields, LoadingState } from "../../libs";
import {
  LoaderButton,
  FormFields,
  Errors,
  PageHeaderWithLogo,
} from "../../components";
import { isValidFormValidation } from "./helpers";
import { createPrimaryAccount as constraints } from "../../validation";
import { BlueLogo as AppLogo } from "../../assets";

import "./CreateAccount.scss";

const { TextInput, EmailField, PasswordField } = FormFields;
const EXCEPTION_DELIMITER = "|";
const USER_LAMBDA_VALIDATION_EXCEPTION = "UserLambdaValidationException";
export default function CreateAccount() {
  const history = useHistory();
  const [loadingStatus, setLoadingStatus] = useState(LoadingState.Idle);
  const [fields, handleFieldChange] = useFormFields({
    firstName: "",
    surname: "",
    username: "",
    fseId: "",
    email: "",
    password: "",
    passwordCheck: "",
  });
  const [validationResults, setValidationResults] = useState({});
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingStatus(LoadingState.Pending);
    await tryCreateAccount();
  };

  const tryCreateAccount = async () => {
    try {
      let results = isValidFormValidation(fields, constraints);
      setValidated(results.valid);
      setValidationResults(results.results);

      if (results.valid) {
        await AuthStore.signUpPrimaryAccount(
          fields.firstName,
          fields.surname,
          fields.username,
          fields.password,
          fields.email,
          fields.fseId
        );
        setLoadingStatus(LoadingState.Resolved);
        history.push("/Login");
      } else {
        setLoadingStatus(LoadingState.Idle);
      }
    } catch (error) {
      let message;
      if (error.code === USER_LAMBDA_VALIDATION_EXCEPTION) {
        message = error.message.split(EXCEPTION_DELIMITER)[1];
      } else {
        message = onError(error);
      }
      setValidationResults({ createAccount: [message] });
      setLoadingStatus(LoadingState.Rejected);
    }
  };

  return (
    <div className="CreateAccount">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={6} lg={8} className="p-0">
            <PageHeaderWithLogo
              logo={AppLogo}
              title={"Create Primary Account"}
            ></PageHeaderWithLogo>
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
              <Errors messages={validationResults.createAccount}></Errors>
              <TextInput
                id="firstName"
                name="firstName"
                label="First Name"
                placeholder=""
                value={fields.firstName}
                handleChange={handleFieldChange}
                isInvalid={validationResults.firstName}
              ></TextInput>
              <TextInput
                id="surname"
                name="surname"
                label="Surname"
                placeholder=""
                value={fields.surname}
                handleChange={handleFieldChange}
                isInvalid={validationResults.surname}
              ></TextInput>
              <TextInput
                id="username"
                name="username"
                label="Username"
                placeholder=""
                value={fields.username}
                handleChange={handleFieldChange}
                isInvalid={validationResults.username}
              ></TextInput>
              <TextInput
                id="fseId"
                name="fseId"
                label="Eclipse unique reference ID"
                placeholder=""
                value={fields.fseId}
                handleChange={handleFieldChange}
                isInvalid={validationResults.fseId}
              ></TextInput>
              <EmailField
                id="email"
                name="email"
                label="Email"
                placeholder="example@email.com"
                value={fields.email}
                handleChange={handleFieldChange}
                isInvalid={validationResults.email}
              ></EmailField>
              <PasswordField
                id="password"
                name="password"
                label="Password"
                value={fields.password}
                handleChange={handleFieldChange}
                isInvalid={validationResults.password}
              ></PasswordField>
              <PasswordField
                id="passwordCheck"
                name="passwordCheck"
                label="Confirm Password"
                value={fields.passwordCheck}
                handleChange={handleFieldChange}
                isInvalid={validationResults.passwordCheck}
              ></PasswordField>
              <LoaderButton
                block
                type="submit"
                size="large"
                loadingStatus={loadingStatus}
                variant={"primary"}
              >
                Create Account
              </LoaderButton>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
