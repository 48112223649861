import AuthStore from "./authStoreLib";
import _ from "lodash";

const getFile = async (url) => {
  return fetch(url);
};

const fileUrlToBase64 = async (blobData) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blobData);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

const downloadAttachment = async (params) => {
  const fileType =
    params.data?.file_type === "PDF" ? "application/pdf" : "image/";
  const url = params.data?.url;
  const file = await getFile(url);
  const blob = await file.blob();
  const fileBase64 = await fileUrlToBase64(blob);

  let dataAttachment = {
    metadata: {
      name: params.data?.name,
      size: blob.size,
      type: fileType,
    },
    stream: fileBase64,
  };

  return dataAttachment;
};

async function getStream(params) {
  let attachmentId = getAttachmentId(params.data, findFileContents);
  if (attachmentId === null) return null;

  let downloadParams = {
    ...params,
    attachmentId,
  };

  return await downloadFileAttachmentContents(downloadParams);
}

const downloadFileAttachmentContents = async (params) => {
  let token = await AuthStore.getAuthenticationToken();

  try {
    var response = await params.axios({
      url: `${params.endpoint}/${params.id}/child/Attachment/${params.attachmentId}`,
      method: "get",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: token,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

const findParent = (data, name) => getLinkByName(data, name);
const findFileContents = (data) => getLinkByName(data, "FileContents");

const getLinkByName = (data, name) => {
  let links = data.links === undefined ? data["@context"].links : data.links;
  let found = _.find(links, function (o) {
    return o.name === name;
  });
  return found;
};

const getLinkPath = (data, fileType, getLink, linkName = null) => {
  let found = linkName ? getLink(data, linkName) : getLink(data);
  if (found === undefined) return null;
  let urlParts = found.href.split(fileType);
  return `${urlParts[1]}`;
};

const getAttachmentId = (data, getLink, linkName = null) => {
  let urlParts = getLinkPath(data, "Attachment/", getLink, linkName);
  return `${urlParts.split("/enclosure")[0]}`;
};
export {
  downloadAttachment,
  findFileContents,
  getStream,
  getLinkPath,
  getAttachmentId,
  findParent,
};
