export const constraints = {
  firstName: {
    presence: true,
    format: {
      pattern: "[a-zA-Z]+",
      flags: "i",
      message: "can only contain a-z",
    },
    length: {
      minimum: 2,
      tooShort: "needs to have %{count} characters or more",
    },
  },
  surname: {
    presence: true,
    format: {
      pattern: "[a-zA-Z]+",
      flags: "i",
      message: "can only contain a-z",
    },
    length: {
      minimum: 2,
      tooShort: "needs to have %{count} characters or more",
    },
  },
  username: {
    presence: true,
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
    length: {
      minimum: 6,
      tooShort: "needs to have %{count} characters or more",
    },
  },
   fseId: {
     presence: true,
     length: {
       minimum: 1,
     },
   },
  email: {
    presence: true,
    email: true,
  },
  password: {
    presence: true,
    length: {
      minimum: 10,
      message: "must be at least 10 characters",
    },
  },
  passwordCheck: {
    presence: true,
    length: {
      minimum: 10,
      message: "must be at least 10 characters",
    },
    equality: {
      attribute: "password",
    },
  },
};
