import React from "react";

import { InputGroup } from "react-bootstrap";
import { password as PasswordIcon } from "../svgs";
import TextInput from "./TextInput";

export default function PasswordField(props) {
  let appendHtml = (
    <InputGroup.Append>
      <InputGroup.Text>
        <PasswordIcon className={"svg green"}></PasswordIcon>
      </InputGroup.Text>
    </InputGroup.Append>
  );
  return (
    <TextInput
      label={props.label}
      id={props.id}
      type="password"
      name={props.name}
      placeholder={props.placeholder}
      inputGroupAppend={appendHtml}
      value={props.value}
      handleChange={props.handleChange}
      isInvalid={props.isInvalid}
    ></TextInput>
  );
}
