import React, { useCallback, useEffect } from "react";
import useWindowDimensions from "../../libs/useWindowDimensions";
import { debounce } from "lodash";


export default function FacebookPublish ({ pageUrl }) {
  const { width, height } = useWindowDimensions()

  useEffect(() => {
    const loadFacebookSDK = () => {
      return new Promise((resolve) => {
        if (window.FB) {
          resolve(window.FB);
        } else {
          const script = document.createElement('script');
          script.id = 'facebook-jssdk';
          script.src = 'https://connect.facebook.net/en_US/sdk.js';
          script.async = true;
          script.defer = true;
          script.onload = () => {
            window.FB.init({
              appId: '1246566396611495',
              xfbml: true,
              version: 'v21.0',
            });
            resolve(window.FB);
          };
          document.body.appendChild(script);
        }
      });
    };

    loadFacebookSDK().then((FB) => {
      if (FB) {
        FB.XFBML.parse();
      }
    });
  }, [pageUrl]);


  const updateFacebookWidget = useCallback(() => {
    const debouncedParse = debounce(() => {
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    }, 500);

    debouncedParse();
  }, []);

  useEffect(() => {
    updateFacebookWidget();
  }, [width, height, updateFacebookWidget]);

  return (
    <div className="FacebookPublish">
      <div id="fb-root"></div>
      <div
        className="fb-page"
        data-href={pageUrl}
        data-tabs="timeline"
        data-width={width}
        data-height={height - 118}
        data-small-header={width < 500 ? true : false}
        data-adapt-container-width={width < 500 ? true : false}
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote cite={pageUrl} className="fb-xfbml-parse-ignore">
          <a href={pageUrl}>Facebook Page</a>
        </blockquote>
      </div>
    </div>
  );
};