import { genPdfPreview } from "./generatePdfPreview";

const fileTypes = {
  pdf: "application/pdf",
  image: "image/",
};

const isPdf = (fileType) => fileType === fileTypes.pdf;
const isImage = (fileType) => fileType.startsWith(fileTypes.image);

const getPdfProperties = async (file) => {
  let streams = await genPdfPreview(file["stream"]);

  return {
    streams,
    streamPreview: streams[0],
  };
};

const getImageProperties = (file) => {
  return getFileProperties(file);
};

const getFileProperties = (file) => {
  return {
    streams: [file["stream"]],
    streamPreview: file["stream"],
  };
};

const addFilePreviewProperties = async (file) => {
  let properties;
  if (isPdf(file.metadata.type)) {
    properties = await getPdfProperties(file);
  } else if (isImage(file.metadata.type)) {
    properties = getImageProperties(file);
  }
  //todo: add csv preview
  else {
    properties = getFileProperties(file);
  }

  let isAppViewableConstraints = [isPdf, isImage];
  file.appViewable = isAppViewableConstraints.some(
    (x) => x(file.metadata.type) === true
  );
  file.streams = properties.streams;
  file.streamPreview = properties.streamPreview;
  return;
};

export { addFilePreviewProperties };
