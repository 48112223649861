import React from "react";
import "./Errors.scss";
export default function Errors({ messages = [] }) {
  let hasMessages = messages.length > 0;

  return hasMessages ? (
    <div className="errors">
      <h4>ERROR:&nbsp;</h4>
      {messages.map((message, id) => {
        return <span key={id}>{message}</span>;
      })}
    </div>
  ) : (
    <></>
  );
}
