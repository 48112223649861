import AuthStore from "../../libs/authStoreLib";
import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import TwitterPublish from "./TwitterPublish";
import FacebookPublish from "./FacebookPublish";

import "./SocialMediaFeed.scss";

export default function SocialMediaFeed () {
  const [handle, setHandle] = useState();
  // twitter or facebook
  const [socialMedia, setSocialMedia] = useState('twitter');

  const loadTimeline = async () => {
    var user = await AuthStore.currentAuthenticatedUser();
    const twitterHandle = user.attributes.twitterHandle

    if (twitterHandle.toLowerCase().includes("facebook")) {
      setSocialMedia("facebook");
    } else {
      setSocialMedia('twitter');
    }

    setHandle(twitterHandle);
  };

  useEffect(() => {
    loadTimeline();
  }, []);

  const renderTimeline = () => {
    return (
      <>
        {socialMedia === 'twitter' && handle && (
          <div className="justify-content-md-center scrollable">
            <TwitterPublish screenName={handle} />
          </div>)}
        {socialMedia === 'facebook' && handle && (
          <div className="justify-content-md-center scrollable">
            <FacebookPublish pageUrl={handle} />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="SocialMediaFeed">
      <Container fluid className={"p-0"}>
        <Navbar id="navbar" bg="light" variant="light">
          <Navbar.Brand href="#">Social Media Feed</Navbar.Brand>
        </Navbar>
        {renderTimeline()}
      </Container>
    </div>
  );
}
