import React from "react";
import "./PageHeaderWithLogo.scss";

export default function PageHeaderWithLogo({ logo, title }) {
  return (
    <div className="heading">
      <img src={logo} alt=""></img>
      <h1>{title}</h1>
    </div>
  );
}
