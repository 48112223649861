import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Figure } from "react-bootstrap";
import LoaderButton from "../LoaderButton";

export default function PreviewFileModal({
  show,
  imageModal,
  filedownloadlink,
  onHide,
  onClickModalButtonClose,
}) {
  const [values, setValues] = useState(imageModal);

  useEffect(() => {
    setValues(imageModal);
  }, [imageModal]);
  const renderAllStreams = (streams) => {
    return streams != null && streams.length > 1 ? (
      <Row>
        {streams.map((stream, index) => (
          <Col
            key={`img-stream-${index}`}
            onClick={() => changeStreamPreview(stream)}
          >
            {renderFigure(stream)}
          </Col>
        ))}
      </Row>
    ) : (
      <></>
    );
  };

  const changeStreamPreview = (src) => {
    let imageModal = { ...values };
    imageModal.streamPreview = src;
    setValues(imageModal);
  };

  const renderFigure = (src) => {
    return (
      <Figure>
        <Figure.Image src={src} />
      </Figure>
    );
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      animation={true}
      size={"lg"}
      aria-labelledby={"contained-modal-title-vcenter"}
      centered
    >
      <Modal.Header closeButton className="text-center">
        <Modal.Title id="container-modal-title-vcenter">
          {values.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="p-0">
          <Row>
            <Col>{renderFigure(values.streamPreview)}</Col>
          </Row>
          {renderAllStreams(values.streams)}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <LoaderButton
          type="button"
          variant="outline-success"
          onClick={onClickModalButtonClose}
          size={"sm"}
          className={"btn-modal"}
        >
          Close
        </LoaderButton>
        <LoaderButton
          type="button"
          variant="outline-success"
          size={"sm"}
          className={"btn-modal"}
          download={values.name}
          href={filedownloadlink}
        >
          Download
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}
